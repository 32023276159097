<!-- Google Tag Manager (noscript) -->
<!--<noscript>-->
<!--  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W7RK4F" height="0" width="0" style="display:none;visibility:hidden"></iframe>-->
<!--</noscript>-->
<!-- End Google Tag Manager (noscript) -->
<ng-progress color="purple"  [thick]="true"></ng-progress>
<div class="all-wrapper menu-side with-pattern">
  <div class="auth-box-w">
    <div class="logo-w">
      <a routerLink="/"><img alt="" src="assets/img/Paynote_-_New_Blue.svg"></a>
    </div>
    <h4 class="auth-header">
      Reset Password
    </h4>
    <form name="form2FA" (ngSubmit)="fResetPas.form.valid && resetPassword()" #fResetPas="ngForm" autocomplete="off">
      <div class="form-group" [ngClass]="{ 'has-error has-danger': !emailPassword.valid && (emailPassword.dirty || emailPassword.touched) }">
        <label>Email</label>
        <input id="resetPassword" name="pinCode" class="form-control" placeholder="Please enter email" type="email" 
               [(ngModel)]="model.emailPassword" #emailPassword="ngModel" required>
        <div class="pre-icon os-icon os-icon-email-2-at top-33"></div>
        <div *ngIf="!emailPassword.valid && (emailPassword.dirty || emailPassword.touched)" 
          class="help-block form-text with-errors form-control-feedback">Email is required</div>
      </div>
      <div class="buttons-w">
        <button [disabled]="loading || !fResetPas.form.valid" class="btn btn-primary btn-rounded btn-rounded-margin" type="submit">Submit</button>
        <br>
        <br>
        <a class="strong" routerLink="/login">Login</a>
        <a class="strong pull-right" routerLink="/create-password">Create Password</a>
      </div>
      <br>
    </form>
  </div>
</div>
