import { Component, OnInit, HostListener } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../../auth.service";
import { environment } from "../../../environments/environment";
import { UserService } from "../../_services/user.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { Router } from "@angular/router";
import { JqueryService } from "../../_services/jquery.service";
import { RouterExtService } from "../../_services/router-ext.service";
import { Location } from "@angular/common";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { timer } from "rxjs";
import * as moment from "moment";
import { Calendar } from "@fullcalendar/core";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { TopAlertsService } from "../../_services/top-alerts.service";
import { Utility } from "../../_helpers/utility";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.css"],
})
export class NavigationComponent implements OnInit {
  public host: string = environment.host;
  public objUser: any;
  public intCountMessages = 0;
  public listMessages: any = [];
  public lightVersion = false;
  public LightVersionAC = false;
  public timerReInitCount;
  public addNewEvent = false;
  public editEvent = false;
  public dateEventFormated = "";
  public dateEdited = "";
  public originalDate = "";
  public scheduleEvent = [];
  public scheduleEventID = null;
  public calendar: any = {};
  public itemEventObj = {
    name: "",
    dwl_customer: {
      id: "",
    },
  };
  public usersList = {};
  // @ts-ignore
  public newEventObj = {
    time: {
      hour: "",
      minute: "",
    },
    shToken: "",
    color: "#3a87ad",
    description: "",
    eventText: "",
    merchant: "",
    link: undefined,
  };

  public modalRef: NgbModalRef;

  constructor(
    private http: HttpClient,
    public userService: UserService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private router: Router,
    private modalService: NgbModal,
    public topAlertsService: TopAlertsService,
    private _location: Location,
    public _jqueryService: JqueryService,
    public utility: Utility,
    private routerService: RouterExtService
  ) {
    this.timerReInitCount = timer(5000, 60000);
    this.timerReInitCount.subscribe(() => {
      this.initListMessages();
    });
  }

  ngOnInit() {
    this.initListMessages();
    // if( this.userService.isAdmin() || this.userService.isSuperAdmin() ) {
    //   this.getEventsSchedule();
    // }
  }

  @HostListener("window:scroll", ["$event"]) onWindowScroll(e) {
    if (window.pageYOffset > 60) {
      const element = document.getElementById("top_bar");
      element.classList.add("sticky");
    } else {
      const element = document.getElementById("top_bar");
      element.classList.remove("sticky");
    }
  }

  logout() {
    this.authenticationService.logout();
    this.userService.clearUser();
  }

  initListMessages() {
    if (!this.userService.isLogined() || !localStorage.getItem("currentUser")) {
      return;
    }
    this.intCountMessages = 0;
    this.listMessages = [];
    if (this.userService.isCustomer() || this.userService.isMerchant()) {
      this.getCountNewInvoices();
      this.getCountNewChecks();
    }
  }

  canShowGoBack() {
    const prevUrl = this.routerService.getPreviousUrl();
    let canShow = true;
    switch (prevUrl) {
      case "/login":
      case "/sign-up":
      case "/reset-password":
      case "/register":
        canShow = false;
        break;
    }
    return canShow;
  }

  goBack() {
    this._location.back();
  }

  openModal(content: any) {
    this.closeModal();
    this.lightVersion = true;
    this.modalRef = this.modalService.open(content, { size: "lg" });
  }
  openModalAC(content: any) {
    this.closeModal();
    this.LightVersionAC = true;
    this.modalRef = this.modalService.open(content, { size: "lg" });
  }
  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }
  showAddScheduleModal(content: any) {
    this.getEventsSchedule(true);
    this.closeModal();
    // this.initEventCalendar();
    this.modalRef = this.modalService.open(content, { size: "lg" });
  }

  getEventsSchedule(initRender = false) {
    // @ts-ignore
    this.http
      .get<any>(this.host + "/schedules/list/" + this.userService.getToken(), {
        params: {
          from_date: moment()
            .startOf("month")
            .subtract(15, "days")
            .format("YYYY-MM-DD"),
          to_date: moment().endOf("month").add(90, "days").format("YYYY-MM-DD"),
          limitIndex: 10000,
          sortProperty: "alert_dt",
          direction: "DESC",
          is_short: true,
        },
      })
      .subscribe((response: any) => {
        if (response.success) {
          this.scheduleEvent = response.data;
          if (initRender) {
            this.initEventCalendar();
            this.calendar.destroy();
            this.calendar.render();
          }
        }
      });
  }

  getUsersList() {
    this.http
      .get<any>(this.host + "/users/list", {
        params: {
          // @ts-ignore
          limitIndex: 10000,
          sortProperty: "created_at",
          direction: "DESC",
        },
      })
      .subscribe((response: any) => {
        if (response.success) {
          this.usersList = response.list.data;
        }
      });
  }

  onChangeEvent($event) {
    this.itemEventObj = $event;
  }

  createNewEvent() {
    const date = moment(this.originalDate).format("YYYY-MM-DD");
    const time = "12:00";
    if (this.newEventObj.time.hour && this.newEventObj.time.minute) {
      // @ts-ignore
      time = this.newEventObj.time.hour + ":" + this.newEventObj.time.minute;
    }
    this.http
      .post<any>(this.host + "/schedules/add", {
        u_token: this.userService.getToken(),
        alert_dt: date,
        msg: this.newEventObj.description,
        action: {
          backgroundColor: this.newEventObj.color,
          title: this.newEventObj.eventText,
          start: date,
          allDay: true,
          time: time,
          description: this.newEventObj.description,
          url:
            "#/users/" +
            this.itemEventObj.dwl_customer.id +
            "/" +
            this.newEventObj.merchant,
          editable: true,
          company_name: this.itemEventObj.name,
        },
      })
      .subscribe(
        (response) => {
          if (response.success) {
            this.getEventsSchedule();
            this.clearEvent();
            this.calendar.addEvent({
              allDay: true,
              backgroundColor: response.data.action.backgroundColor,
              company_name: response.data.action.company_name,
              description: response.data.action.description,
              editable: true,
              id: response.data.id,
              sh_token: response.data.sh_token,
              start: response.data.action.start,
              time: response.data.action.time,
              title: response.data.action.title,
              url: response.data.action.url,
            });
            this.topAlertsService.popToast(
              "success",
              "",
              "Event added successfully"
            );
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              "error",
              "Error",
              this.utility.errorMessage
            );
          }
        }
      );
  }
  deleteEvent(event) {
    const shEvent = this.calendar.getEventById(this.scheduleEventID);
    if (shEvent != null) {
      shEvent.remove();
    } else {
      this.closeModal();
    }
    this.http
      .post<any>(this.host + "/schedules/remove", {
        sh_token: event.shToken,
      })
      .subscribe(
        (response) => {
          if (response.success) {
            this.getEventsSchedule();
            this.clearEvent();

            this.topAlertsService.popToast(
              "success",
              "",
              "Event deleted successfully"
            );
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              "error",
              "Error",
              this.utility.errorMessage
            );
          }
        }
      );
  }

  editEventSh() {
    const date = moment(this.dateEdited).format("YYYY-MM-DD");
    const time =
      this.newEventObj.time.hour + ":" + this.newEventObj.time.minute;

    this.http
      .post<any>(this.host + "/schedules/edit", {
        sh_token: this.newEventObj.shToken,
        alert_dt: date,
        msg: this.newEventObj.description,
        action: {
          backgroundColor: this.newEventObj.color,
          title: this.newEventObj.eventText,
          start: date,
          allDay: true,
          time: time,
          description: this.newEventObj.description,
          url: this.newEventObj.link,
          editable: true,
          company_name: this.newEventObj.merchant,
        },
      })
      .subscribe(
        (response) => {
          if (response.success) {
            this.getEventsSchedule();
            this.clearEvent();
            const shEvent = this.calendar.getEventById(this.scheduleEventID);
            if (shEvent) {
              shEvent.remove();
            }
            this.calendar.addEvent({
              allDay: true,
              backgroundColor: response.data.action.backgroundColor,
              company_name: response.data.action.company_name,
              description: response.data.action.description,
              editable: true,
              id: response.data.id,
              sh_token: response.data.sh_token,
              start: response.data.action.start,
              time: response.data.action.time,
              title: response.data.action.title,
              url: response.data.action.url,
            });
            this.topAlertsService.popToast(
              "success",
              "",
              "Event updated successfully"
            );
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              "error",
              "Error",
              this.utility.errorMessage
            );
          }
        }
      );
  }

  // @HostListener('click', ['$event']) onClick() {
  //   if (event.srcElement.className === 'fa fa-calendar') {
  //     this.getEventsSchedule();
  //     this.initEventCalendar();
  //     this.calendar.destroy();
  //     this.calendar.render();
  //   }
  // }

  initEventCalendar() {
    this.getUsersList();
    const calendarEl = document.getElementById("mycalendar");
    this.calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
      header: {
        left: "prev,next",
        center: "title",
        right: "today",
      },
      selectable: true,
      editable: true,
      eventLimit: 2,
      aspectRatio: 1.5,
      eventDragMinDistance: 1,
      selectMirror: true,
      displayEventTime: true,
      eventClick: function (info) {
        console.log(info);
      },
      eventDrop: (event) => this.dragAndDropSchEvent(event),
      // tslint:disable-next-line:no-shadowed-variable
      dateClick: (event) => this.addSchEvent(event),
      events: this.scheduleEvent,
      eventRender: (info) => this.eventRenderFn(info),
    });
  }

  eventRenderFn(info) {
    JqueryService.addAttrTitle(info.el, info.event.extendedProps.description);
    // tslint:disable-next-line:max-line-length
    this._jqueryService.prependElement(
      info.el,
      '&nbsp;<i class="fa fa-pencil" style="font-size: 16px;float: left;padding: 0 5px;" aria-hidden="true"></i>&nbsp;'
    );
    this._jqueryService.onClickEvent(info.el, info.event.url, this, info);
  }

  convert(str) {
    const dateM = new Date(str),
      mnth = ("0" + (dateM.getMonth() + 1)).slice(-2),
      day = ("0" + dateM.getDate()).slice(-2);
    return [dateM.getFullYear(), mnth, day].join("-");
  }

  public dragAndDropSchEvent(event) {
    const date = this.convert(event.event.start);
    this.http
      .post<any>(this.host + "/schedules/edit", {
        sh_token: event.event.extendedProps.sh_token,
        alert_dt: date,
        msg: event.event.extendedProps.description,
        action: {
          backgroundColor: event.event.backgroundColor,
          title: event.event.title,
          start: date,
          allDay: true,
          time: event.event.extendedProps.time,
          description: event.event.extendedProps.description,
          url: event.event.url,
          editable: true,
          company_name: event.event.extendedProps.company_name,
        },
      })
      .subscribe(
        (response) => {
          if (response.success) {
            this.getEventsSchedule();
            this.clearEvent();
            /*            this.calendar.addEvent({
              allDay: true,
              backgroundColor: response.data.action.backgroundColor,
              company_name: response.data.action.company_name,
              description: response.data.action.description,
              editable: true,
              id: event.event.id,
              sh_token: response.data.sh_token,
              start: response.data.action.start,
              time: response.data.action.time,
              title: response.data.action.title,
              url: response.data.action.url
            });*/
            this.topAlertsService.popToast(
              "success",
              "",
              "Event updated successfully"
            );
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              "error",
              "Error",
              this.utility.errorMessage
            );
          }
        }
      );
  }

  public callbackEditEvent(info) {
    this.editEvent = true;
    this.addNewEvent = false;
    this.getEventByToken(info.event.extendedProps.sh_token);
  }

  addSchEvent(event) {
    this.clearEvent();
    this.addNewEvent = true;
    this.editEvent = false;
    this.originalDate = event.date;
    this.dateEventFormated = moment(event.date).format("dddd, MMMM Do YYYY");
  }

  clearEvent() {
    this.addNewEvent = false;
    this.editEvent = false;
    // @ts-ignore
    this.newEventObj = {
      time: {
        hour: "",
        minute: "",
      },
      shToken: "",
      color: "#3a87ad",
      description: "",
      eventText: "",
      merchant: "",
      link: undefined,
    };
  }

  getEventByToken(token = null) {
    this.http
      .get<any>(this.host + "/get/schedules", { params: { sh_token: token } })
      .subscribe((response: any) => {
        console.log(response.data);
        if (response && response.data) {
          const hourEv = Number(
            moment(response.data.time, "hh:mm").format("HH")
          );
          const minuteEv = Number(
            moment(response.data.time, "hh:mm").format("mm")
          );
          this.scheduleEventID = response.data.id;
          this.dateEventFormated = moment(response.data.alert_dt.date).format(
            "dddd, MMMM Do YYYY"
          );
          this.dateEdited = response.data.alert_dt.date;
          this.newEventObj.merchant = response.data.company_name;
          this.newEventObj.shToken = token;
          this.newEventObj.link = response.data.link;
          // @ts-ignore
          this.newEventObj.time = { hour: hourEv, minute: minuteEv };
          this.newEventObj.eventText = response.data.title;
          this.newEventObj.color = response.data.icon_color;
          this.newEventObj.description = response.data.description;
        }
      });
  }

  getCountNewInvoices() {
    this.http
      .get<any>(this.host + "/client/check/invoice/new/count", {
        params: { u_token: this.userService.getToken() },
      })
      .subscribe((response: any) => {
        if (response.success && response.count > 0) {
          this.listMessages.push({
            message1:
              response.count == 1
                ? "New Payment Request"
                : "New Payment Requests",
            message2:
              "You have new " +
              response.count +
              " Payment Request" +
              (response.count == 1 ? "" : "s"),
            link: "/request-payments",
            status: "requestPayments",
          });
          this.intCountMessages += 1;
          this.userService.canShowVerifyMicroDeposit = true;
        }
      });
  }

  getCountNewChecks() {
    this.http
      .get<any>(this.host + "/client/check/payment-link/unpaid/count", {
        params: { u_token: this.userService.getToken() },
      })
      .subscribe((response: any) => {
        if (response.success && response.count > 0) {
          this.listMessages.push({
            message1: response.count == 1 ? "New Check" : "New Checks",
            message2:
              "You have new " +
              response.count +
              " Check" +
              (response.count == 1 ? "" : "s"),
            status: "acceptChecks",
          });
          this.intCountMessages += 1;
        }
      });
  }

  getCountEmptyEmailTemplates() {
    this.http
      .get<any>(this.host + "/mail/template/empty")
      .subscribe((response: any) => {
        if (response.success && response.count > 0) {
          this.listMessages.push({
            message1: "Dwolla Required",
            message2: "Missing templates (" + response.count + ")",
            link: "/mail-template",
          });
          this.intCountMessages += 1;
        }
      });
  }

  prepareRedirectToSandBox() {
    const objDataDialog = {
      title: "Confirm mode switch",
      text: "You are switching to <b>Sandbox</b> mode. This will allow you to test how the system works.\n\
         <br><br>The background will be yellow in <b>Sandbox</b> mode.",
      button_cancel_text: "Cancel",
      button_confirm_text: "Confirm",
      confirm: "redirectToSandBox",
    };
    this.openDialog(objDataDialog);
  }
  prepareRedirectToLive() {
    const objDataDialog = {
      title: "Confirm mode switch",
      text: 'You are switching to <b>Live</b> mode. You can toggle back to test mode by clicking on the "Go To Sandbox" button in the top left corner.\n\
              <br><br> The background will be white in Live mode.',
      button_cancel_text: "Cancel",
      button_confirm_text: "Confirm",
      confirm: "redirectToSandBox",
    };
    this.openDialog(objDataDialog);
  }

  signUpToLive() {
    this._jqueryService.addClass(".onboarding-modal", "show-on-load");
    this._jqueryService.showModal(".onboarding-modal.show-on-load", {
      backdrop: "static",
      keyboard: false,
      showClose: true,
    });
  }

  getCurrentUrl() {
    return "/#" + this.router.url;
  }

  redirectToSandBox() {
    window.location.href = environment.sandboxHost + this.getCurrentUrl();
  }
  redirectToLive() {
    window.location.href = environment.liveHost + this.getCurrentUrl();
  }

  openDialog(objDataDialog: any): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      data: objDataDialog,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (objDataDialog.confirm === "redirectToSandBox") {
          this.redirectToSandBox();
        }
      }
    });
  }
}
