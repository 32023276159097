import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {JqueryService} from '../../_services/jquery.service';
import {ErrorService} from '../../_services/error.service';
import {MessagesService} from '../../_services/messages.service';
import {FormBuilder, Validators} from '@angular/forms';
import {Utility} from '../../_helpers/utility';
import {AuthenticationService} from '../../auth.service';
import {validatePhone} from '../../_vaidators/phone';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mobile-verification-light',
  templateUrl: './mobile-verification-light.component.html',
  styleUrls: ['./mobile-verification-light.component.css']
})
export class MobileVerificationLightComponent implements OnInit {

  verifyPhoneFormGroup: any;

  @Input() objExistUser: any;
  @Input() objPhoneMask: any;
  @Input() curentAccount: any;
  @Input() isExistUser: any;
  @Input() objPaymentLink: any;
  @Input() checkOrInvoice: any;

  @Output() nextStepAfterVerify = new EventEmitter<boolean>();
  @Output() moveToPreviousStep = new EventEmitter<boolean>();
  @Output() pinCodePhoneOutput: EventEmitter<any> = new EventEmitter();

  public phoneObj: {
    textMessageColor: string,
    phoneCallColor: string,
    phoneButtonClass: boolean,
    textMessageButtonClass: boolean
    phoneSuccess: boolean
  };
  loaded = false;
  public pnoneForVerify: any;
  public countTimer: any = '';
  public timerCountDown = 15;
  public timerIsLoadding = false;

  constructor(
    public jqueryService: JqueryService,
    public errorService: ErrorService,
    public messages: MessagesService,
    private _formBuilder: FormBuilder,
    public utility: Utility,
    private authenticationService: AuthenticationService
  ) {
    this.errorService.clearAlerts();
    this.phoneObj = {
      textMessageColor: 'btn-primary',
      phoneCallColor: 'btn-primary',
      phoneButtonClass: true,
      textMessageButtonClass: true,
      phoneSuccess: false
    };
  }

  ngOnInit() {
    this.jqueryService.setFocus('#phone');
    this.verifyPhoneFormGroup = this._formBuilder.group({
      phone_pin: ['', Validators.compose([
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4)
      ])],
      phone: ['', Validators.compose([
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14),
        validatePhone
      ])]
    });
  }

  visiblePhone() {
     if (this.checkOrInvoice == 'invoice') {
       return this.objPhoneMask  ? true : false;
     }
     if (this.checkOrInvoice == 'check') {
       return this.objExistUser.masked_phone && this.objExistUser.phone ? true : false;
     }
  }

  startCountDown(seconds) {
    let counter = seconds;
    this.timerIsLoadding = true;
    const interval = setInterval(() => {
       this.countTimer = counter;
      counter--;
      if (counter < 0 ) {
        clearInterval(interval);
        this.countTimer = '';
        this.timerIsLoadding = false;
      }
    }, 1000);
  }

  clearVerifyCode() {
    this.verifyPhoneFormGroup.reset({
      phone_pin: ''
    });
  }

  verifyPhoneCode() {
    this.errorService.clearAlerts();
    const emailForVerify = this.objPaymentLink.email ? this.objPaymentLink.email : this.objPaymentLink.sndr_email;

    this.authenticationService
      .sendVerifyTextVoiceCode(this.pnoneForVerify, this.verifyPhoneFormGroup.get('phone_pin').value, emailForVerify)
      .subscribe(
        result => {
          this.phoneObj.phoneSuccess = result.success;
          const objResp = <any>result;
          if (objResp.success) {
            this.loaded = false;
            // tslint:disable-next-line:max-line-length
            this.pinCodePhoneOutput.emit({ phone: this.verifyPhoneFormGroup.get('phone').value, phonepincode: this.verifyPhoneFormGroup.get('phone_pin').value}  );
            this.nextStepAfterVerify.emit(true);
           /* this.verifyPhoneFormGroup.reset({
              phone_pin: '',
              phone: ''
            });*/
            this.phoneObj.phoneSuccess = false;
            this.phoneObj.phoneButtonClass = true;
            this.phoneObj.textMessageButtonClass = true;
          }
        },
        err => {
          if (err.error) {
            this.errorService.getMessageError(err.error);
          }
        }
      );

  }

  returnToPreviosStep() {
    if (this.checkOrInvoice == 'invoice') {
      this.moveToPreviousStep.emit(false);
    } else {
      this.objExistUser.masked_phone ? this.moveToPreviousStep.emit(false) : this.moveToPreviousStep.emit(true);
    }
  }

  resendVerifyCode() {
    const emailForVerify = this.objPaymentLink.email ? this.objPaymentLink.email : this.objPaymentLink.sndr_email;
    this.jqueryService.setFocus('#phone_pin');
    this.startCountDown(this.timerCountDown);
    this.verifyPhoneFormGroup.reset({
      phone_pin: '',
      phone: this.pnoneForVerify
    });
    if (this.phoneObj.textMessageButtonClass) {
      this.authenticationService
        .sendVerifyCodeToTextMessage(this.pnoneForVerify, emailForVerify)
        .subscribe(
          result => {
            this.phoneObj.phoneSuccess = result.success;
            const objResp = <any>result;
            if (objResp.success) {
              this.loaded = true;
              this.errorService.getMessageSuccess(objResp);
            }
          },
          err => {
            if (err.error) {
              this.phoneObj.phoneSuccess = false;
              this.phoneObj.phoneButtonClass = true;
              this.phoneObj.textMessageButtonClass = true;
              this.loaded = false;
              this.jqueryService.setFocus('#phone');
              this.clearVerifyCode();
              this.errorService.getMessageError(err.error);
            }
          }
        );
    }
    if (this.phoneObj.phoneButtonClass) {
      this.authenticationService
        .sendVerifyCodeToPhoneCall(this.pnoneForVerify, emailForVerify)
        .subscribe(
          result => {
            this.phoneObj.phoneSuccess = result.success;
            const objResp = <any>result;
            if (objResp.success) {
              this.loaded = true;
              this.errorService.getMessageSuccess(objResp);
            }
          },
          err => {
            if (err.error) {
              this.phoneObj.phoneSuccess = false;
              this.phoneObj.phoneButtonClass = true;
              this.phoneObj.textMessageButtonClass = true;
              this.loaded = false;
              this.jqueryService.setFocus('#phone');
              this.clearVerifyCode();
              this.errorService.getMessageError(err.error);
            }
          }
        );
    }
  }

  sendPhonePinCode(actionSendCodeNAme: string = '') {
    const emailForVerify = this.objPaymentLink.email ? this.objPaymentLink.email : this.objPaymentLink.sndr_email;

    this.errorService.clearAlerts();

    if (this.objExistUser && this.objExistUser.phone) {
      this.pnoneForVerify = this.objExistUser.phone;
    } else {
      this.pnoneForVerify = this.verifyPhoneFormGroup.get('phone').value;
    }

    if ( actionSendCodeNAme == 'TextMessage' ) {
      this.phoneObj.phoneButtonClass = false;
      this.phoneObj.textMessageButtonClass = true;
      this.authenticationService
        .sendVerifyCodeToTextMessage(this.pnoneForVerify, emailForVerify)
        .subscribe(
          result => {
            this.startCountDown(this.timerCountDown);
            this.phoneObj.phoneSuccess = result.success;
            const objResp = <any>result;
            if (objResp.success) {
              this.loaded = true;
              this.errorService.getMessageSuccess(objResp);
              this.verifyPhoneFormGroup.reset({
                phone_pin: '',
                phone: this.pnoneForVerify
              });
              setTimeout(() => this.jqueryService.setFocus('#phone_pin'), 200);
            }
          },
          err => {
            if (err.error) {
              this.verifyPhoneFormGroup.reset({
                phone_pin: '',
                phone: ''
              });
              this.phoneObj.phoneSuccess = false;
              this.phoneObj.phoneButtonClass = true;
              this.phoneObj.textMessageButtonClass = true;
              this.loaded = false;
              this.jqueryService.setFocus('#phone');
              this.errorService.getMessageError(err.error);
            }
          }
        );
    }
    if ( actionSendCodeNAme == 'PhoneCall' ) {
      this.phoneObj.textMessageButtonClass = false;
      this.phoneObj.phoneButtonClass = true;
      this.authenticationService
        .sendVerifyCodeToPhoneCall(this.pnoneForVerify, emailForVerify)
        .subscribe(
          result => {
            this.startCountDown(this.timerCountDown);
            this.phoneObj.phoneSuccess = result.success;
            const objResp = <any>result;
            if (objResp.success) {
              this.loaded = true;
              this.errorService.getMessageSuccess(objResp);
              this.verifyPhoneFormGroup.reset({
                phone_pin: '',
                phone: this.pnoneForVerify
              });
              setTimeout(() => this.jqueryService.setFocus('#phone_pin'), 200);
            }
          },
          err => {
            if (err.error) {
              this.verifyPhoneFormGroup.reset({
                phone_pin: '',
                phone: ''
              });
              this.phoneObj.phoneSuccess = false;
              this.phoneObj.phoneButtonClass = true;
              this.phoneObj.textMessageButtonClass = true;
              this.loaded = false;
              this.jqueryService.setFocus('#phone');
              this.errorService.getMessageError(err.error);
            }
          }
        );
    }
  }

}
