import { Injectable } from "@angular/core";

declare var $: any;
// declare var Plaid: any;

@Injectable({
  providedIn: "root",
})
export class JqueryService {
  public isInitMenu = false;
  public strMenuColorScheme = "color-scheme-light"; // color-scheme-dark
  // public strMenuColorScheme = 'color-scheme-dark'; // color-scheme-dark
  public strSubMenuColorScheme = "sub-menu-color-bright"; // sub-menu-color-dark
  public strMenuSize = "menu-layout-compact"; // menu-layout-mini
  public bToogleMenuSize = false; // false - menu-layout-compact, true - menu-layout-mini
  public bShowToogleMenuSize = false; // false - menu-layout-compact, true - menu-layout-mini
  constructor() {
    if (localStorage.getItem("dark_menu")) {
      this.enableDarkMenu();
    }
    if (localStorage.getItem("test_toogle_menu")) {
      this.bToogleMenuSize = true;
      this.bShowToogleMenuSize = true;
      this.strMenuSize = "menu-layout-mini"; // menu-layout-mini
    }
    this.initInterface();
    setTimeout(() => this.getClientIp(), 2000);
  }

  enableDarkMenu() {
    localStorage.setItem("dark_menu", "1");
    this.strMenuColorScheme = "color-scheme-dark";
    this.strSubMenuColorScheme = "sub-menu-color-dark";
    this.initInterface();
  }
  disableDarkMenu() {
    localStorage.removeItem("dark_menu");
    this.strMenuColorScheme = "color-scheme-light";
    this.strSubMenuColorScheme = "sub-menu-color-bright";
    this.initInterface();
  }

  os_init_mobile_menu() {
    // INIT MOBILE MENU TRIGGER BUTTON
    $(".mobile-menu-trigger")
      .unbind("click")
      .on("click", function () {
        $(".menu-mobile .menu-and-user").slideToggle(200, "swing");
        return false;
      });
  }

  os_init_mobile_link() {
    if (!this.isInitMenu) {
      this.isInitMenu = true;
      setTimeout(function () {
        $(".menu-mobile a.mobile-item")
          .unbind("click")
          .click(function () {
            $(".menu-mobile .menu-and-user").slideToggle(500, "swing");
          });
      }, 1000);
    }
  }

  os_init_sub_menus() {
    // INIT MENU TO ACTIVATE ON HOVER
    let menu_timer;
    $(".menu-activated-on-hover").on(
      "mouseenter",
      "ul.main-menu > li.has-sub-menu",
      function () {
        const $elem = $(this);
        clearTimeout(menu_timer);
        $elem
          .closest("ul")
          .addClass("has-active")
          .find("> li")
          .removeClass("active");
        $elem.addClass("active");
      }
    );

    $(".menu-activated-on-hover").on(
      "mouseleave",
      "ul.main-menu > li.has-sub-menu",
      function () {
        const $elem = $(this);
        menu_timer = setTimeout(function () {
          $elem.removeClass("active").closest("ul").removeClass("has-active");
        }, 30);
      }
    );

    // INIT MENU TO ACTIVATE ON CLICK
    $(".menu-activated-on-click").on(
      "click",
      "li.has-sub-menu > a",
      function (event) {
        const $elem = $(this).closest("li");
        if ($elem.hasClass("active")) {
          $elem.removeClass("active");
        } else {
          $elem.closest("ul").find("li.active").removeClass("active");
          $elem.addClass("active");
        }
        return false;
      }
    );
  }

  initLeftSideBarActions() {
    // console.log( $('.menuItemUsers') )
    $(".menuItemUsers").click(function () {
      console.log("fdsfdasfasd");
      localStorage.removeItem("usersList");
    });
  }

  initInterface() {
    this.os_init_main_menu();
    // INIT MENU
    this.os_init_sub_menus();

    // #16. OUR OWN CUSTOM DROPDOWNS
    setTimeout(function () {
      $(".os-dropdown-trigger").on("mouseenter", function () {
        $(this).addClass("over");
      });
      $(".os-dropdown-trigger").on("mouseleave", function () {
        $(this).removeClass("over");
      });
    }, 1000);
    this.os_init_mobile_menu();
    this.os_init_mobile_link();

    // #22. Colors Toggler
    //    let vm = this
    //    $('.floated-colors-btn').on('click', function () {
    //      vm.applyColorTheme()
    //    });

    setTimeout(function () {
      $(".floated-customizer-btn, .button-filters")
        .unbind("click")
        .on("click", function () {
          return $(".floated-customizer-panel").toggleClass("active");
        });
      $(".close-customizer-btn")
        .unbind("click")
        .on("click", function () {
          $(".floated-customizer-panel").removeClass("active");
        });

      $(document).mouseup(function (e) {
        const block = $(".floated-customizer-panel");
        if (!block.is(e.target) && block.has(e.target).length === 0) {
          block.removeClass("active");
        }
      });
    }, 1000);

    setTimeout(function () {
      $(".alert-relogin-modal.show-relogin").modal({
        backdrop: "static",
        keyboard: false,
      });

      $(".onboarding-modal.show-on-load").modal({
        backdrop: "static",
        keyboard: false,
      });
    }, 1000);
    setTimeout(function () {
      $(".onboarding-sandbox-modal.show-on-load").modal({
        backdrop: "static",
        keyboard: false,
        showClose: true,
      });
    }, 1000);
    setTimeout(function () {
      $(".funding-source-setup-modal.show-on-load").modal({
        backdrop: "static",
        keyboard: false,
        showClose: true,
      });
    }, 1000);
    // setInterval(function () {
    //   $(".funding-source-setup-modal.show-on-load").modal({
    //     backdrop: "static",
    //     keyboard: false,
    //     showClose: true,
    //   });
    // }, 1000);
    setTimeout(function () {
      $(".password-setup-modal.show-on-load").modal({
        backdrop: "static",
        keyboard: false,
        showClose: true,
      });
    }, 1000);
  }

  os_init_main_menu() {
    this.bToogleMenuSize =
      !localStorage.getItem("main_menu_size") ||
      localStorage.getItem("main_menu_size") == "menu-layout-mini";
    this.resetClassMainMenu();
    this.addClass("div.menu-w", this.strMenuColorScheme);
    this.addClass("div.menu-w", this.strSubMenuColorScheme);
    const strClassTheme = localStorage.getItem("main_menu_size")
      ? localStorage.getItem("main_menu_size")
      : this.strMenuSize;
    this.addClass("div.menu-w", strClassTheme);
    // let vm = this;
    // $('div.menu-w').unbind( 'mouseenter' ).on('mouseenter', function () {
    //   if( vm.bToogleMenuSize ) {
    //     vm.removeClass('div.menu-w', 'menu-layout-mini');
    //     vm.addClass('div.menu-w', 'menu-layout-compact');
    //   }
    // });
    // $('div.menu-w').unbind( 'mouseleave' ).on('mouseleave', function () {
    //   if( vm.bToogleMenuSize ) {
    //     vm.removeClass('div.menu-w', 'menu-layout-compact');
    //     vm.addClass('div.menu-w', 'menu-layout-mini');
    //   }
    // });
  }

  resetClassMainMenu() {
    this.removeClass("div.menu-w", "color-scheme-light");
    this.removeClass("div.menu-w", "color-scheme-dark");
    this.removeClass("div.menu-w", "sub-menu-color-bright");
    this.removeClass("div.menu-w", "sub-menu-color-dark");
    this.removeClass("div.menu-w", "menu-layout-compact");
    this.removeClass("div.menu-w", "menu-layout-mini");
  }

  showModal(strSelector: string, options: any) {
    if (options) {
      $(strSelector).modal(options);
    } else {
      $(strSelector).modal("show");
    }
  }

  showPurchasePlanModal() {
    $(".onboarding-purchase-plan-modal").modal({
      backdrop: "static",
      keyboard: false,
      showClose: true,
    });
    $("#initListViewOurPlans").click();
  }

  closeModal(strSelector: string) {
    $(strSelector).modal("hide");
  }

  removeSelector(selector: string) {
    $(selector).remove();
  }

  showTabBySelector(selector: string) {
    $(selector).tab("show");
  }

  addClass(selector: string, className: string) {
    $(selector).addClass(className);
  }
  removeClass(selector: string, className: string) {
    $(selector).removeClass(className);
  }

  prependElement(elementParent: any, element: string) {
    $(elementParent).prepend(element);
  }

  onClick(selector: string = "") {
    $(selector).click();
    // $(selector).trigger('click');
  }

  onClickEvent(selector: any, url: string, callback: any, data: any) {
    $(selector).click(function (event) {
      if (event.target.className == "fa fa-pencil") {
        if (callback && callback.callbackEditEvent != undefined) {
          callback.callbackEditEvent(data);
        }
        event.preventDefault();
      } else {
        callback.closeModal();
        //callback.router.navigateByUrl(url);
        window.location.hash = url;
      }
    });
  }
  // tslint:disable-next-line:member-ordering
  static addAttrTitle(selector: any, attrInfo: string) {
    $(selector).attr({ title: attrInfo });
  }

  findParent(selector: string, parentSelector: string = "") {
    $(selector).parents(parentSelector);
  }

  changeValue(selector: string, value: string) {
    $(selector).val( value );
  }

  setFocus(selector: string) {
    $(selector).focus();
  }

  setFocusAditional(selector: string, input) {
    $(selector).find(input).focus();
  }
  resetFocus(selector: string) {
    $(selector).blur();
  }

  emulateKeyPress(selector: string) {
    $(selector).keypress();
  }

  setContent(selector: string, content: string = "") {
    $(selector).html(content);
  }

  resetFile(selector: string) {
    const input = $(selector);
    input.replaceWith(input.val("").clone(true));
  }

  setValue(selector: string, value: string = "") {
    $(selector).val(value);
  }

  getValueFromParentElement(selector: string, parentElement: string) {
    return $(selector).find(parentElement).val();
  }

  findAndHide(selector: string, classHide: string) {
    $(selector).find(classHide).hide();
  }
  findAndShow(selector: string, classShow: string) {
    $(selector).find(classShow).show();
  }

  resetForm(selector: string) {
    $(selector)[0].reset();
  }

  toggleWraper(selector: string) {
    $(selector).toggle("slow");
  }

  getBase64UrlFromCanvas(selector: string) {
    const canvas: any = document.getElementsByTagName(selector).item(0);
    if (canvas) {
      return canvas.toDataURL();
    }
  }

  clearCanvas(selector: string) {
    const canvas: any = document.getElementsByTagName(selector).item(0);
    if (canvas) {
      canvas.width = canvas.width;
    }
  }

  haveItemInLocalStorage(itemName: string) {
    return localStorage.getItem(itemName) ? true : false;
  }

  getClientIp() {
    if (localStorage.getItem("ipClient")) {
      return;
    }
    //$.getJSON('https://jsonip.com?callback=?', function (data) {
    $.getJSON("https://api.db-ip.com/v2/free/self", function (data) {
      //{
      //  "ipAddress": "145.239.104.62",
      //  "continentCode": "EU",
      //  "continentName": "Europe",
      //  "countryCode": "GB",
      //  "countryName": "United Kingdom",
      //  "stateProv": "England",
      //  "city": "London (Tower)"
      //}
      if (data.ipAddress) {
        localStorage.setItem("ipClient", data.ipAddress);
      } else {
        localStorage.removeItem("ipClient");
      }
    });
  }

  getDomElement(selector: string) {
    return $(selector);
  }

  initAppTextEditor(
    strIdSelector: string = "",
    content: any = "",
    objConfig: any = null
  ) {
    $(strIdSelector).trumbowyg(objConfig);
    $(strIdSelector).trumbowyg("html", content);
  }

  getHtmlFromEditor(strIdSelector: string = "") {
    return $(strIdSelector).trumbowyg("html");
  }

  pushLeadConversion() {
    // @ts-ignore
    if (window.gtag) {
      console.log("conversion set");
      // @ts-ignore
      window.gtag("event", "conversion", {
        send_to: "AW-964268047/ve58CO2LkKwBEI-g5ssD",
      });
    }
  }

  pushBusinesLeadConversion() {
    // @ts-ignore
    if (window.gtag) {
      console.log("conversion set");
      // @ts-ignore
      window.gtag("event", "conversion", {
        send_to: "AW-667011747/QOrLCJDP5MgBEKOVh74C",
      });
    }
  }

  scrollWrapper(selector: string, intScroll = 0, animateSpeed = "4000") {
    return $(selector).animate({ scrollTop: intScroll }, animateSpeed); // .scrollTop( intScroll );
  }
}
