import { AbstractControl, ValidatorFn } from '@angular/forms';

export function isContainedInArray (arrayOptions: Array<any>): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const arr = [];
    for (let i = 0; i < arrayOptions.length; ++i) {
      const innerArray = arrayOptions[i].list;
      innerArray.forEach(item => {
        arr.push(item.name);
      });
    }
    const test = arr.includes(control.value);
    if (!test) {
      return { 'badСhoice': true };
    }
      return null;
  };
}
