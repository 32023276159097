import { Injectable } from "@angular/core";
import { ConfirmDialogComponent } from "../views/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(public dialog: MatDialog) {}

  openDialog(objDataDialog: any) {
    return this.dialog.open(ConfirmDialogComponent, {
      width: "500px",
      data: objDataDialog,
    });
  }
}
