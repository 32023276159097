<ng-http-loader
  [backgroundColor]="'#047bf8'"
  [spinner]="spinkit.skWave"
  [debounceDelay]="100"
  [minDuration]="300"
>
</ng-http-loader>
<div
  class="all-wrapper menu-side with-pattern"
  [ngClass]="{ 'partner-signup': bIsPartnerSignUp }"
>
  <div class="auth-box-w" *ngIf="showSignUpForm || showPhoneVerifyForm">
    <div class="logo-w">
      <span style="display: table">
        <a *ngIf="!bIsPartnerSignUp" href="/"
        ><img alt="" src="assets/img/Paynote_-_New_Blue.svg"
        /></a>
        <img
          *ngIf="bIsPartnerSignUp"
          alt=""
          src="assets/img/Paynote_-_New_Blue.svg"
        />
      </span>
    </div>
    <div [hidden]="!showSignUpForm">
      <h4
        *ngIf="!bIsPartnerSignUp"
        class="auth-header"
        style="padding-left: 40px; padding-right: 40px"
      >
        Welcome, <b>{{ objManagerInfo.name }}</b
      >, please continue registration.
      </h4>
      <div [ngClass]="{ row: bIsPartnerSignUp }">
        <div
          class="col-sm-12 col-md-6 col-lg-6 partner-signup-info"
          *ngIf="bIsPartnerSignUp && isPartnerName"
        >
          <h3 class="signup-head w-color" style="color: black">
            <b>{{ objPartner.name }}</b>
            {{ objPartner.slogan }}
          </h3>
          <br />
          <span [innerHTML]="objPartner.body"></span>
        </div>
        <div
          class="col-sm-12 col-md-6 col-lg-6 partner-signup-info"
          *ngIf="bIsPartnerSignUp && !isPartnerName"
        >
          &nbsp;
        </div>
        <form
          name="form"
          autocomplete="nope"
          (ngSubmit)="f.form.valid && signUpValidate()"
          [formGroup]="signUpForm"
          #f="ngForm"
          class="form-validation"
          [ngClass]="{
            'col-sm-12': bIsPartnerSignUp,
            'col-md-6': bIsPartnerSignUp,
            'col-lg-6': bIsPartnerSignUp
          }"
        >
          <div
            class="form-group"
            [ngClass]="{
              'has-error has-danger':
                signUpForm.get('phone').errors &&
                (signUpForm.get('phone').dirty ||
                  signUpForm.get('phone').touched)
            }"
          >
            <label>Phone</label>
            <input
              id="signUpPhoneInput"
              name="phone"
              class="form-control"
              placeholder="Phone"
              type="text"
              formControlName="phone"
              required
              [textMask]="{ mask: utility.maskPhone }"
            />
            <div class="pre-icon os-icon os-icon-phone top-33"></div>
            <div
              *ngIf="
                signUpForm.get('phone').errors &&
                (signUpForm.get('phone').dirty ||
                  signUpForm.get('phone').touched)
              "
              class="help-block form-text with-errors form-control-feedback"
            >
              <span *ngIf="signUpForm.get('phone').errors.validPhone">
                Phone must be at least 10 digits long.
              </span>
              <span *ngIf="signUpForm.get('phone').errors.required">
                Phone is required
              </span>
            </div>
          </div>

          <!--          <div-->
          <!--            class="form-group"-->
          <!--            [ngClass]="{-->
          <!--              'has-error has-danger':-->
          <!--                signUpForm.get('password').errors &&-->
          <!--                (signUpForm.get('password').dirty ||-->
          <!--                  signUpForm.get('password').touched)-->
          <!--            }"-->
          <!--          >-->
          <!--            <label-->
          <!--              >Password-->
          <!--              <i-->
          <!--                ngbTooltip="{{ messages.get('PASSWORD_REQUIREMENTS') }}"-->
          <!--                aria-hidden="true"-->
          <!--                class="fa fa-question-circle"-->
          <!--                placement="bottom"-->
          <!--              ></i-->
          <!--            ></label>-->
          <!--            <input-->
          <!--              name="password"-->
          <!--              class="form-control"-->
          <!--              placeholder="Password"-->
          <!--              type="password"-->
          <!--              formControlName="password"-->
          <!--              required-->
          <!--              minlength="8"-->
          <!--              autocomplete="nope"-->
          <!--            />-->
          <!--            <div-->
          <!--              class="pre-icon os-icon os-icon-fingerprint top-33"-->
          <!--              style="top: 32px"-->
          <!--            ></div>-->
          <!--            <div-->
          <!--              *ngIf="-->
          <!--                signUpForm.get('password').errors &&-->
          <!--                (signUpForm.get('password').dirty ||-->
          <!--                  signUpForm.get('password').touched)-->
          <!--              "-->
          <!--              class="help-block form-text with-errors form-control-feedback"-->
          <!--            >-->
          <!--              <span *ngIf="signUpForm.get('password').errors.minlength">-->
          <!--                Password must be at least 8 characters long.-->
          <!--              </span>-->
          <!--              <span *ngIf="signUpForm.get('password').errors.required">-->
          <!--                Password is required-->
          <!--              </span>-->
          <!--            </div>-->
          <!--            <ng9-password-strength-bar-->
          <!--              [passwordToCheck]="signUpForm.value.password"-->
          <!--              [barLabel]="barLabel"-->
          <!--              [barColors]="myColors"-->
          <!--            >-->
          <!--            </ng9-password-strength-bar>-->
          <!--          </div>-->

          <!--          <div-->
          <!--            class="form-group"-->
          <!--            [ngClass]="{-->
          <!--              'has-error has-danger':-->
          <!--                signUpForm.get('cpassword').errors &&-->
          <!--                (signUpForm.get('cpassword').dirty ||-->
          <!--                  signUpForm.get('cpassword').touched)-->
          <!--            }"-->
          <!--          >-->
          <!--            <label>Confirm Password</label>-->
          <!--            <input-->
          <!--              name="cpassword"-->
          <!--              class="form-control"-->
          <!--              placeholder="Confirm Password"-->
          <!--              type="password"-->
          <!--              formControlName="cpassword"-->
          <!--              required-->
          <!--              autocomplete="off"-->
          <!--              validateEqual="password"-->
          <!--            />-->
          <!--            <div class="pre-icon os-icon os-icon-fingerprint top-33"></div>-->
          <!--            <div-->
          <!--              *ngIf="-->
          <!--                signUpForm.get('cpassword').errors &&-->
          <!--                (signUpForm.get('cpassword').dirty ||-->
          <!--                  signUpForm.get('cpassword').touched)-->
          <!--              "-->
          <!--              class="help-block form-text with-errors form-control-feedback"-->
          <!--            >-->
          <!--              Password mismatch-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="text-left">
            <label style="font-size: 14px; color: black">
              <input
                formControlName="acceptPolicy"
                required
                type="checkbox"
                class="form-control"
              />&nbsp;&nbsp; I have read and accept the Paynote
              <a
                href="https://www.seamlesschex.com/terms-of-service/"
                target="_blank"
              >
                <b>Terms of Service</b>
              </a>
              and
              <a
                href="https://www.seamlesschex.com/privacy-policy/"
                target="_blank"
              >
                <b>Privacy Policy</b> </a
              >, as well as our partner Dwolla’s
              <a href="https://www.dwolla.com/legal/tos/" target="_blank">
                <b>Terms of Service</b>
              </a>
              and
              <a href="https://www.dwolla.com/legal/privacy/" target="_blank">
                <b>Privacy Policy</b> </a
              >.
            </label>
          </div>

          <div class="buttons-w">
            <button
              [disabled]="!signUpForm.valid"
              type="submit"
              class="btn btn-primary btn-rounded btn-rounded-margin"
            >
              Sign Up
            </button>
            <!--<button *ngIf="!bIsPartnerSignUp" class="btn btn-default pull-right btn-rounded btn-rounded-margin" routerLink="/login">Login</button>-->
          </div>
        </form>
      </div>
    </div>

    <div [hidden]="!showPhoneVerifyForm">
      <!--<h4 class="auth-header" style="padding-right: 40px;font-size: 20px;">
        <span *ngIf="isVoiceCode()">Please answer your phone and enter the code provided to complete your sign up</span>
        <span *ngIf="!isVoiceCode()">Verify your phone</span>
      </h4>-->
      <div [ngClass]="{ row: bIsPartnerSignUp }">
        <div
          class="col-sm-12 col-md-6 col-lg-6 partner-signup-info"
          style="padding-bottom: 40px"
          *ngIf="bIsPartnerSignUp"
        >
          <h3 class="signup-head w-color" style="color: black">
            <b>{{ objPartner.name }}</b>
            {{ objPartner.slogan }}
          </h3>
          <br />
          <span [innerHTML]="objPartner.body"></span>
        </div>
        <form
          name="form2FA"
          (ngSubmit)="f2FA.form.valid && signUp()"
          #f2FA="ngForm"
          autocomplete="off"
          [formGroup]="verifyPhoneForm"
          [ngClass]="{
            'col-sm-12': bIsPartnerSignUp,
            'col-md-6': bIsPartnerSignUp,
            'col-lg-5': bIsPartnerSignUp
          }"
          (keydown)="signUp($event)"
          style="padding-top: 0"
        >
          <div
            class="form-group"
            [ngClass]="{
              'has-error has-danger':
                verifyPhoneForm.get('phone_pin').errors &&
                (verifyPhoneForm.get('phone_pin').dirty ||
                  verifyPhoneForm.get('phone_pin').touched)
            }"
          >
            <h4
              class="auth-header1"
              style="padding-right: 40px; font-size: 20px; margin-bottom: 30px"
            >
              <span *ngIf="isVoiceCode()"
              >Please answer your phone and enter the code provided to
                complete your sign up</span
              >
              <span *ngIf="!isVoiceCode()">
                <span *ngIf="!availableSignUpByEmal()">Verify your phone</span>
                <span *ngIf="availableSignUpByEmal()">Verify your email</span>
              </span>
            </h4>
            <label for="phone_pin">
              <span *ngIf="isVoiceCode()">Voice Code</span>
              <span *ngIf="!isVoiceCode()">Pin Code</span>
            </label>
            <input
              name="phone_pin"
              class="form-control"
              placeholder="Code"
              type="text"
              maxlength="4"
              appNumbers
              formControlName="phone_pin"
              required
              id="phone_pin"
            />
            <div class="pre-icon" style="top: 79px">
              <i class="icon-feather-hash"></i>
            </div>
            <div
              *ngIf="
                verifyPhoneForm.get('phone_pin').errors &&
                (verifyPhoneForm.get('phone_pin').dirty ||
                  verifyPhoneForm.get('phone_pin').touched)
              "
              class="help-block form-text with-errors form-control-feedback"
            >
              <span *ngIf="verifyPhoneForm.get('phone_pin').errors.required">
                Code is required
              </span>
              <span *ngIf="verifyPhoneForm.get('phone_pin').errors.minlength">
                Code must be at least 4 digits long.
              </span>
            </div>
          </div>

          <button
            style="padding: 0; font-size: 12px; float: left"
            [disabled]="loading || timerService.isTimerRun"
            type="button"
            class="btn btn-link"
            (click)="sendVerifyPhoneCode()"
          >
            <span *ngIf="isVoiceCode()">Resend Voice Code </span>
            <span *ngIf="!isVoiceCode()">Resend Code </span>
            <span [innerHTML]="timerService.getRemainigTime()"></span>
          </button>
          <button
            style="padding: 0; font-size: 12px; float: right"
            type="button"
            [disabled]="loading"
            class="btn btn-link"
            (click)="backToSignUpForm()"
          >
            <span>Change Phone</span>
          </button>
          <br />
          <span style="display: none"
          ><countdown-timer
            *ngIf="timerService.isTimerRun"
            [countDownTimerConfig]="timerService.configCD"
          ></countdown-timer
          ></span>
          <div class="buttons-w">
            <button
              [disabled]="loading"
              type="button"
              class="btn btn-default btn-rounded"
              (click)="backToSignUpForm()"
            >
              Back
            </button>
            &nbsp;
            <button
              type="submit"
              [disabled]="loading || !verifyPhoneForm.valid"
              class="btn btn-primary pull-right btn-rounded"
            >
              Submit & Verify
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
