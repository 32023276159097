import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../auth.service';
import {ErrorService} from '../../_services/error.service';
import {UserService} from '../../_services/user.service';
import {JqueryService} from '../../_services/jquery.service';
import {TimerService} from '../../_services/timer.service';
import {environment} from '../../../environments/environment';
import {MessagesService} from '../../_services/messages.service';
import {Utility} from '../../_helpers/utility';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.css']
})
export class CreatePasswordComponent implements OnInit {

  public host = environment.host;
  public model: any = {};
  public loading = false;
  public isPhoneDisabled = false;
  public isSendTextMessage = false;
  public isSendVoiceMessage = false;
  public isFirstStep = false;
  public isSecondStep = false;
  public setupPasswordForm: any = {};
  public objPassInfo: any = {};

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    public errorService: ErrorService,
    public userService: UserService,
    public messages: MessagesService,
    public jqueryService: JqueryService,
    private router: ActivatedRoute,
    public utility: Utility,
    public timerService: TimerService
  ) {}

  ngOnInit() {
    // reset login status
    if ( this.router.snapshot.paramMap.get('create_password_token') ) {
      this.timerService.initTimerCD('down');
      this.setupPasswordForm.token = this.router.snapshot.paramMap.get('create_password_token');
      this.isSecondStep = true;
      this.verifyPassword();
    } else {
      this.isFirstStep = true;
    }

    this.errorService.clearAlerts();
    this.jqueryService.removeSelector('.modal-backdrop.fade');
    this.jqueryService.removeSelector('.modal.fade.show.d-block');

  }

  verifyPassword() {
    const objRequest = {
      token: this.setupPasswordForm.token
    };
    this.http.get<any>(this.host + '/user/password/create/verify', {params: objRequest})
      .subscribe(
        response => {
          if (response.success) {
            this.objPassInfo = response;
            if ( response.phone ) {
              this.setupPasswordForm.phone = response.phone;
              this.isPhoneDisabled = true;
            }
          }
        },
        errResponse => {
          this.loading = false;
          if ( errResponse.error ) {
            this.errorService.getMessageError( errResponse.error );
          }
        }
      );
  }

  createPassword() {
    this.errorService.clearAlerts();
    this.loading = true;
    this.authenticationService.initiateCreatePassword(this.model.emailPassword )
      .subscribe(
        result => {
          if ( result ) {
            this.errorService.getMessageSuccess(result);
            this.loading = false;
          }
        },
        err => {
          this.loading = false;
          if ( err.error ) {
            this.errorService.getMessageError( err.error );
          }
        }
      );
  }

  sendVerifyTextCode() {
    this.timerService.setTime(15, 's');
    this.authenticationService.sendVerifyCodeToTextMessage( this.setupPasswordForm.phone, this.objPassInfo.email )
      .subscribe(
        result => {
          const objResp = <any>result;
          if (objResp.success) {
            this.setupPasswordForm.verifyCodeSend = true;
            this.isSendTextMessage = true;
            this.isSendVoiceMessage = false;
            this.jqueryService.setFocus('#phone_pin');
            this.errorService.getMessageSuccess(objResp);
          }
        },
        err => {
          if ( err.error ) {
            this.errorService.getMessageError( err.error );
          }
        }
      );
  }

  sendVerifyVoiceCode() {
    this.timerService.setTime(15, 's');
    this.authenticationService.sendVerifyCodeToPhoneCall( this.setupPasswordForm.phone, this.objPassInfo.email )
      .subscribe(
        result => {
          const objResp = <any>result;
          if (objResp.success) {
            this.setupPasswordForm.verifyCodeSend = true;
            this.isSendTextMessage = false;
            this.isSendVoiceMessage = true;
            this.jqueryService.setFocus('#phone_pin');
            this.errorService.getMessageSuccess(objResp);
          }
        },
        err => {
          if ( err.error ) {
            this.errorService.getMessageError( err.error );
          }
        }
      );
  }

  setupPasswordPhoneRegister() {
    this.errorService.clearAlerts();
    this.loading = true;
    this.authenticationService.createPasswordAndPhone(this.setupPasswordForm)
      .subscribe(
        result => {
          this.isFirstStep = false;
          this.isSecondStep = false;
          this.loading = false;
          const objResp = <any> result;
          localStorage.setItem('currentUser', JSON.stringify(objResp));
          this.errorService.getMessageSuccess({message: this.messages.get('YOUR_PASSWORD_HAS_BEEN_SUCCESSFULLY_SET')});
          this.userService.initUser(true);
        },
        err => {
          this.loading = false;
          if (err.error) {
            this.errorService.getMessageError(err.error);
          }
        }
      );
  }

  availableSignUpByEmal() {
    return environment.availableSignUpByEmal;
  }
}
