<ng-http-loader
  [backgroundColor]="'#047bf8'"
  [spinner]="spinkit.skWave"
  [debounceDelay]="100"
  [minDuration]="300">
</ng-http-loader>
<div class="express-add-bank-account-body">
  <div class="auth-box-w">
<!--    <div class="logo-w" style="padding: 5%;">-->
<!--      <img alt="Logo" src="{{getLogo()}}" style="max-height: 120px;">-->
<!--    </div>-->
  </div>
</div>

<ng-template #foundingSourcesVerifiedDialog let-c="close" let-d="dismiss">
  <div class="modal-blue-bg onboarding-content">
    <div class="modal-header">
      <h4 class="modal-title">Please verify your account</h4>
    </div>
    <form name="form" (ngSubmit)="f.form.valid && verifyFundingSourcesBank()" #f="ngForm">
      <div class="modal-body">
        <div class="profile-tile">
          <div class="profile-tile-meta" style="padding-left: 0px;">
            <ul>
              <li>
                <span class="text-initial vfs-text">
                  Please enter the two micro-deposit amounts made to your <i>{{modelVerifyFundSource.data.bank}}</i> ending in
                  <i>{{modelVerifyFundSource.data.lbacc}}</i> on or around <i>{{userService.getDateFormat(modelVerifyFundSource.data.created_at)}}</i>
                </span>
              </li>
              <li>
                <br>
              </li>
              <li>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group" [ngClass]="{ 'has-error has-danger': !amount1.valid && (amount1.dirty || amount1.touched) }">
                      <label for="Amount1">Amount 1</label>
                      <input id="Amount1" name="amount1" class="form-control" placeholder="$0.__" type="text" required
                             [(ngModel)]="modelVerifyFundSource.amount1" #amount1="ngModel" currencyMask>
                      <div *ngIf="!amount1.valid && (amount1.dirty || amount1.touched)"
                           class="help-block form-text with-errors form-control-feedback">
                             <span *ngIf="amount1.errors.required">
                          Amount 1 is required
                        </span>
                      </div>
                      <span class="text-initial ex-style">ex. $0.07</span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group" [ngClass]="{ 'has-error has-danger': !amount2.valid && (amount2.dirty || amount2.touched) }">
                      <label for="Amount2">Amount 2</label>
                      <input id="Amount2" name="amount2" class="form-control" placeholder="$0.__" type="text" required
                             [(ngModel)]="modelVerifyFundSource.amount2" #amount2="ngModel" currencyMask>
                      <div *ngIf="!amount2.valid && (amount2.dirty || amount2.touched)"
                           class="help-block form-text with-errors form-control-feedback">
                             <span *ngIf="amount2.errors.required">
                          Amount 2 is required
                        </span>
                      </div>
                      <span class="text-initial ex-style">ex. $0.15</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="slick-prev slick-arrow" type="button" (click)="backToMarketPlace()">Cancel</button>
        <button [disabled]="isLoading || !f.form.valid" class="slick-next slick-arrow" type="submit">Verify</button>
      </div>
    </form>
  </div>
</ng-template>
