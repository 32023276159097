<!-- Google Tag Manager (noscript) -->
<!--<noscript>-->
<!--  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W7RK4F" height="0" width="0" style="display:none;visibility:hidden"></iframe>-->
<!--</noscript>-->
<!-- End Google Tag Manager (noscript) -->
<ng-progress color="purple" [thick]="true" [trickleSpeed]="500" id="myProgress"></ng-progress>
<!--<ng-http-loader 
    [backgroundColor]="'#ff0000'"
    [spinner]="spinkit.skCubeGrid"
    [debounceDelay]="100"
    [minDuration]="300">
</ng-http-loader>-->
<div class="all-wrapper with-side-panel solid-bg-all ">
<!--  <app-search></app-search>-->
  <div class="layout-w">
<!--    style="background-color: #293145 !important; background-image: none !important;"-->
    <app-left-sidebar class="{{jquery.strMenuColorScheme}}"></app-left-sidebar>
    <div class="content-w" style="position: relative; height: auto !important;">
      <app-navigation></app-navigation>
      <div class="content-i" style="padding-bottom: 50px; display: block;">
        <div class="alert alert-warning text-center" role="alert" style="margin-bottom: 0px;color: black;" *ngIf="userService.isApiSandBoxMode()">
          You are in <b>Sandbox</b> Environment &nbsp;&nbsp;
          <button class="btn btn-rounded btn-sm btn-primary" (click)="userService.goToLiveMode()">GO <b>LIVE</b></button>
        </div>
        <app-user-actions></app-user-actions>
        <div class="content-box" style="padding-right: 1rem;">
          <router-outlet></router-outlet>
<!--          <div class="floated-colors-btn second-floated-btn noShowInMobile">
            <div class="os-toggler-w">
              <div class="os-toggler-i">
                <div class="os-toggler-pill"></div>
              </div>
            </div>
            <span>Dark </span><span>Colors</span>
          </div>-->
          <div class="center-bottom-terms-and-condition" style="margin-top: 20px;">
            <div class="bottom-logout-top-box text-center">
              <a class="bottom-logout-link" href="https://www.seamlesschex.com/privacy-policy/" target="_blank">Privacy Policy</a>
              &nbsp;&nbsp;&nbsp;
              <a class="bottom-logout-link" href="https://www.seamlesschex.com/terms-of-service/" target="_blank">Terms of Service</a>
            </div>
          </div>
        </div>
      </div>
      <footer style="padding: 15px; background: white; position: absolute; bottom: 0;left: 0; width: 100%; height: 50px;">
        <a class="report_an_issue" href="javascript:void(0)" (click)="openModal(sendBugReport)">Report an issue</a>
      </footer>
      <!--<app-footer>-->
      <!--        <span><a href="https://coreui.io">CoreUI</a> &copy; 2018 creativeLabs.</span>
              <span class="ml-auto">Powered by <a href="https://coreui.io/angular">CoreUI for Angular</a></span>-->
      <!--</app-footer>-->
    </div>
  </div>
</div>
<app-password-setup *ngIf="!userService.isAdmin() && !userService.isSuperAdmin()"></app-password-setup>
<app-onboard-register-in-dwolla *ngIf="!userService.isAdmin() && !userService.isSuperAdmin() && !userService.isDwollaVerified()"></app-onboard-register-in-dwolla>
<app-onboard-register-in-sandbox *ngIf="!userService.isAdmin() && !userService.isSuperAdmin() && !userService.isDwollaVerified()"></app-onboard-register-in-sandbox>

<ng-template #sendBugReport let-c="close" let-d="dismiss">
  <div class="manual-verification">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
              <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="manual-verification-title">
        <h4 class="modal-title">Send Bug Report</h4>
      </div>
      <form name="form" autocomplete="nope">
        <div class="form-group">
          <label for="">Bug description</label>
          <textarea class="form-control" name="description" placeholder="Description"
                    [(ngModel)]="objBugReportForm.description" #description="ngModel"></textarea>
        </div>
        <p><label>This sends a screenshot of your page directly to our engineers.</label></p>
      </form>
    </div>
    <div class="modal-footer">
      <button class="slick-prev slick-arrow" type="button" style="" (click)="c('Cross click')">Cancel</button>
      <button class="slick-next slick-arrow" type="submit" style="" (click)="sendBugReportAction()">Submit</button>
    </div>
  </div>
</ng-template>

