import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AuthenticationService } from "../../auth.service";
import { Utility } from "../../_helpers/utility";
import { UserService } from "../../_services/user.service";
import { MessagesService } from "../../_services/messages.service";
import { Spinkit } from "ng-http-loader";
import { JqueryService } from "../../_services/jquery.service";
import { TopAlertsService } from "../../_services/top-alerts.service";
import {NgxPlaidLinkService, PlaidLinkHandler} from 'ngx-plaid-link';
import {PlaidClientService} from '../../_services/plaid-client.service';
// declare var Plaid: any;

@Component({
  selector: "app-external-bank-accaunt",
  templateUrl: "./external-bank-accaunt.component.html",
  styleUrls: ["./external-bank-accaunt.component.css"],
})
export class ExternalBankAccauntComponent implements OnInit {
  public spinkit = Spinkit;
  public host: string = environment.host;
  public modalRef: NgbModalRef;
  public isLoading = false;
  public isSandbox = false;
  public isAddBA = false;
  public isVerifyBA = false;
  public isUpdateBA = false;
  public uToken = "";
  public publicToken = "";
  public baToken = "";
  public successUrl = "";
  public cancelUrl = "";
  public modelVerifyFundSource = <any>{};
  public plaidCredentials = <any>{};

  public objComp: any = {
    apiLinks: <any>environment.api,
  };

  @ViewChild("foundingSourcesVerifiedDialog", { static: true })
  private foundingSourcesVerifiedDialog: TemplateRef<any>;

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private router: ActivatedRoute,
    public utility: Utility,
    public userService: UserService,
    public messages: MessagesService,
    public topAlertsService: TopAlertsService,
    public jqueryService: JqueryService,
    private plaidService: PlaidClientService,
    private route: Router,
  ) {}

  ngOnInit() {
    this.userService.clearUser();
    this.authenticationService.clearLogin();

    this.uToken = this.router.snapshot.paramMap.get("u_token");
    this.publicToken = this.router.snapshot.paramMap.get("public_token");
    this.baToken = this.router.snapshot.paramMap.get("ba_token");

    this.successUrl = this.router.snapshot.queryParamMap.get("successUrl");
    this.cancelUrl = this.router.snapshot.queryParamMap.get("cancelUrl");

    this.isSandbox = environment.subDomen == "sandbox";
    this.isAddBA = this.router.snapshot.data["isAddBA"];
    this.isVerifyBA = this.router.snapshot.data["isVerifyBA"];
    this.isUpdateBA = this.router.snapshot.data["isUpdateBA"];
    this.getUserPlaidKey();
    if( !this.isUpdateBA ) {
      this.plaidCredentials = <any>(
        (this.isSandbox ? environment.plaidSandbox : environment.plaid)
      );
      this.plaidCredentials.onSuccess = this.onPlaidSuccess
      this.plaidCredentials.onExit = this.onPlaidExit
    } else {
      this.plaidService.getUpdateLinkTokenForCustomer( this.baToken, this.uToken );
      setTimeout( () => { 
        if( this.plaidService.isHaveUpdateLinkToken() ) {
          this.plaidService.initUpdateBankAcc( this );
        } else {
          this.topAlertsService.popToast(
            "error",
            "Error",
            this.messages.get("LINK_BANK_ACCOUNT_RE_SYNC_EXPIRED")
          );
          setTimeout( () => { this.route.navigate(['/login']) }, 3000);
        }
      }, 1500 )
    }

    if (!this.uToken || !this.publicToken) {
      return;
    }

    if (this.isAddBA) {
      this.openPlaidModal();
    }

    if (this.isVerifyBA) {
      this.getBankAccountInfo();
    }
  }

  onPlaidUpdateSuccess(token: string, data: any) {
    this.http.get<any>(this.host + "/dwl/customer/bank-account/re-initialized/success", {
       params: {fs_token: this.baToken},
      }).subscribe(() => {
            this.topAlertsService.popToast(
              "success",
              "Success",
              "Your bank account has been re-connected"
            );
            if (this.successUrl) {
              window.location.href = this.successUrl;
            } else {
              this.route.navigate(['/login']);
            }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              "error",
              "Error",
              this.utility.errorMessage
            );
          }
        }
      );
  }

  onPlaidSuccess(objAccountInfo: any) {
    const vm = this;
    const headers = {
      Authorization: this.publicToken,
    };
    const request = <any>{
      account_id: objAccountInfo.metadata.account_id,
      token: objAccountInfo.token,
      user_id: vm.uToken,
    };
    vm.http
      .post<any>(
        vm.getHostApi() + "/funding-source/instant/verification",
        request,
        { headers: headers }
      )
      .subscribe(
        (response) => {
          if (response.success) {
            vm.isLoading = false;
            vm.topAlertsService.popToast(
              "success",
              "",
              "Bank Account Successfully Created"
            );
            if (vm.successUrl) {
              window.location.href = vm.successUrl;
            }
          }
        },
        (errResponse) => {
          vm.isLoading = false;
          if (errResponse.error) {
            vm.utility.getMessageError(errResponse.error);
            vm.topAlertsService.popToast(
              "error",
              "Error",
              vm.utility.errorMessage
            );
            setTimeout(() => {
              this.openPlaidModal();
            }, 2000);
          }
        }
      );
  }

  onPlaidExit(event) {
    this.isLoading = false;
    if (this.cancelUrl) {
      window.location.href = this.cancelUrl;
    }
  }

  getUserPlaidKey() {
    let plaidKey = "";
    this.http
      .get<any>(this.host + "/dwl/customer/plaid/key", {
        params: { key: this.publicToken },
      })
      .subscribe((keyResponse) => {
          if (keyResponse.success) {
            plaidKey = keyResponse.key;
            if (plaidKey !== "") {
              this.plaidCredentials.clientName = keyResponse.name;
              this.plaidCredentials.key = plaidKey;
            }
          }
        }
      );
  }

  openPlaidModal(modalContent?, backContent?) {
    this.isLoading = true;
    this.plaidService.init(this, { key: this.publicToken }, modalContent, backContent)
      .then((plaidHandler: PlaidLinkHandler) => {
        this.isLoading = false;
        plaidHandler.open();
      });
  }

  getHostApi() {
    return this.isSandbox
      ? this.objComp.apiLinks.sandbox_endpoint
      : this.objComp.apiLinks.live_endpoint;
  }

  openDialog(content: any) {
    this.closeModal();
    this.modalRef = this.modalService.open(content, { backdrop: false });
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  getUpdateLinkToken() {
    this.isLoading = true;

    this.http
      .post<any>(
        this.getHostApi() + "/funding-source/manual/verification",
        {
          verification: 1,
          source_id: this.baToken,
          user_id: this.uToken,
          amount1: this.modelVerifyFundSource.amount1,
          amount2: this.modelVerifyFundSource.amount2,
        }
      )
      .subscribe(
        (response) => {
          if (response.success) {
            this.isLoading = false;
            this.closeModal();
            this.topAlertsService.popToast(
              "success",
              "Success",
              this.messages.get("FUNDING_SOURCE_VERIFY_SUCCESSFULLY")
            );
            setTimeout(() => {
              if (this.successUrl) {
                window.location.href = this.successUrl;
              }
            }, 2000);
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.isLoading = false;
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              "error",
              "Error",
              this.utility.errorMessage
            );
          }
        }
      );
  }

  verifyFundingSourcesBank() {
    const headers = {
      Authorization: this.publicToken,
    };

    this.isLoading = true;

    this.http
      .post<any>(
        this.getHostApi() + "/funding-source/manual/verification",
        {
          verification: 1,
          source_id: this.baToken,
          user_id: this.uToken,
          amount1: this.modelVerifyFundSource.amount1,
          amount2: this.modelVerifyFundSource.amount2,
        },
        { headers: headers }
      )
      .subscribe(
        (response) => {
          if (response.success) {
            this.isLoading = false;
            this.closeModal();
            this.topAlertsService.popToast(
              "success",
              "Success",
              this.messages.get("FUNDING_SOURCE_VERIFY_SUCCESSFULLY")
            );
            setTimeout(() => {
              if (this.successUrl) {
                window.location.href = this.successUrl;
              }
            }, 2000);
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.isLoading = false;
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              "error",
              "Error",
              this.utility.errorMessage
            );
          }
        }
      );
  }

  getBankAccountInfo() {
    const headers = {
      Authorization: this.publicToken,
    };

    this.isLoading = true;
    this.http
      .post<any>(
        this.getHostApi() + "/funding-source/manual/verification",
        { verification: 0, source_id: this.baToken, user_id: this.uToken },
        { headers: headers }
      )
      .subscribe(
        (response) => {
          if (response.success) {
            this.isLoading = false;
            this.modelVerifyFundSource.data = response.funding_source;
            this.openDialog(this.foundingSourcesVerifiedDialog);
          }
        },
        (errResponse) => {
          this.isLoading = false;
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              "error",
              "Error",
              this.utility.errorMessage
            );
          }
        }
      );
  }

  backToMarketPlace() {
    if (this.cancelUrl) {
      window.location.href = this.cancelUrl;
    } else {
      this.closeModal();
    }
  }
}
