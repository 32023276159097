<div class="sticky menu-mobile menu-activated-on-click color-scheme-dark">
  <div class="mm-logo-buttons-w">
    <a *ngIf="userService.isAdmin() || userService.isSuperAdmin()" class="mm-logo" href="/users">
      <img src="assets/img/PaynoteLogoNewBlue.svg">
    </a>
    <a *ngIf="userService.isMerchant() || userService.isCustomer() || userService.isDemoUser()" class="mm-logo" href="/transactions">
      <img src="assets/img/PaynoteLogoNewBlue.svg">
    </a>
    <div class="mm-buttons">
      <div class="content-panel-open" *ngIf="winterIsComing()">
       <!-- <div class="os-icon os-icon-grid-circles" (click)="jqueryService.addClass('.all-wrapper', 'content-panel-active')" ></div>-->
      </div>
      <div class="mobile-menu-trigger">
        <div class="os-icon os-icon-hamburger-menu-1"></div>
      </div>
    </div>
  </div>
  <div class="menu-and-user">
    <!--------------------
    START - Mobile Menu List
    -------------------->

    <ul class="main-menu" *ngIf="userService.isMerchant() || userService.isCustomer() || userService.isDemoUser() || userService.isAdmin() || userService.isSuperAdmin() || userService.isPartner() || userService.isRep()">
        <li *ngFor="let item of userService.menu" id="{{item.id}}" [ngClass]="{'hide-li': !item.permissionValue, 'has-sub-menu': item.subMenu }">
<!--        <a [href]="item.sref" (click)="callAction(item.clickAction)"  class="mobile-item" [ngClass]="{'mobile-item': !item.subMenu }">-->
        <a [routerLink]="[item.sref]" (click)="callAction(item.clickAction)"  class="mobile-item" [ngClass]="{'mobile-item': !item.subMenu }">
          <div class="icon-w">
            <i *ngIf="!item.image" class="{{item.iconClasses}}"></i>
            <img *ngIf="item.image" class="np_image" src="assets/img/{{item.mobile_src}}">
          </div>
          <span>{{item.name}}</span>
        </a>
        <ul class="sub-menu" *ngIf="item.subMenu">
          <li *ngFor="let submenu of item.menu" id="{{submenu.id}}">
<!--            <a href="{{submenu.sref}}" class="mobile-item">{{submenu.name}}</a>-->
            <a [routerLink]="[submenu.sref]" class="mobile-item">{{submenu.name}}</a>
          </li>
        </ul>
      </li>
    </ul>
    <!--------------------
    END - Mobile Menu List
    -------------------->
  </div>
</div>
<div class="sticky menu-w color-style-transparent menu-position-side menu-side-left menu-layout-compact sub-menu-style-over selected-menu-color-light menu-activated-on-hover menu-has-selected-link scrollSidebar">
  <div class="logo-w" id="logo_w">
    <a class="logo" href="javascript:void(0)" (click)="checkAndRedirect()">
      <img class="toogleLogoCompact" src="assets/img/PaynoteNewBlue@2x.png">
      <img class="toogleLogoMini" src="assets/img/PaynoteLogoNewBlue.svg">
    </a>
    <a href="javascript:void(0)" (click)="toogleMenu()" class="toogleMenu" *ngIf="jqueryService.bShowToogleMenuSize">
      <i class="picons-thin-icon-thin-0069a_menu_hambuger"></i>
    </a>
  </div>
  <ul id="MainMenu" class="main-menu" *ngIf="userService.isMerchant() || userService.isCustomer() || userService.isDemoUser() || userService.isAdmin() || userService.isSuperAdmin() || userService.isPartner() || userService.isRep()"
    [ngClass]="{ 'main-menu-admin': userService.isAdmin() || userService.isSuperAdmin() }" >
      <li *ngFor="let item of userService.menu" placement="right" ngbTooltip="{{item.name}}"
          tooltipClass="menuToggleClass" [disableTooltip]="jqueryService.bToogleMenuSize" id="{{item.id}}"
          [ngClass]="{'hide-li': !item.permissionValue, 'has-sub-menu': item.subMenu }">
      <!--<a [href]="item.sref" (click)="callAction(item.clickAction)">-->
      <a [routerLink]="[item.sref]" (click)="callAction(item.clickAction)">
        <div class="icon-w">
          <i *ngIf="!item.image" class="{{item.iconClasses}}"></i>
          <img *ngIf="item.image" class="np_image" src="assets/img/{{item.src}}">
        </div>
        <span>{{item.name}}</span>
      </a>
      <div class="sub-menu-w" *ngIf="item.subMenu">
        <div class="sub-menu-header">{{item.name}}</div>
        <div class="sub-menu-icon">
          <i class="{{item.iconClasses}}"></i>
        </div>
        <div class="sub-menu-i">
          <ul class="sub-menu">
            <li *ngFor="let submenu of item.menu" id="{{submenu.id}}">
              <!--<a href="{{submenu.sref}}">-->
              <a [routerLink]="[submenu.sref]">
                <i class="{{submenu.iconClasses}}"></i>{{submenu.name}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
</div>
<div class="left-menu-bottom-logout">
  <div class="left-menu-bottom-logout-top-box" *ngIf="!userService.isAdmin() && !userService.isSuperAdmin() && !jqueryService.bToogleMenuSize">
    <a class="left-menu-bottom-logout-link" href="{{objLink.privacy_policy}}" target="_blank">Privacy Policy</a>
    <a class="left-menu-bottom-logout-link" href="{{objLink.terms_of_service}}" target="_blank">Terms of Service</a>
  </div>
</div>
