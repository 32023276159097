import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private objPermission: any = {};
  private objManager: any = {};
  constructor() { }

  init( objManager: any = null ) {
    this.objManager = objManager;
    if ( this.objManager && this.objManager.permissions ) {
      this.objPermission = this.objManager.permissions;
    }
  }

  isManager() {
    return this.objManager && this.objManager.permissions ? true : false;
  }
  isViewEdit() {
    return this.objPermission.write == 1 ? true : false;
  }
  isOnlyRead() {
    return this.objPermission.read != 1;
  }


}
