import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AcceptChecksRoutingModule } from "./accept-checks-routing.module";
import { AcceptChecksComponent } from "./accept-checks.component";
import { FormsModule } from "@angular/forms";
import { PaginationModule } from "ngx-pagination-bootstrap";
import { TrendsModule } from "../../_shared_modules/trends/trends.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DatepickerModule, BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [
    CommonModule,
    AcceptChecksRoutingModule,
    FormsModule,
    PaginationModule,
    TrendsModule,
    MatTooltipModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    NgbTooltipModule,
  ],
  exports: [AcceptChecksComponent],
  declarations: [AcceptChecksComponent],
})
export class AcceptChecksModule {}
