import { Component, Input, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { UserService } from "../../_services/user.service";
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { NgForm, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PaginationService } from "../../_services/pagination.service";
import { TopAlertsService } from "../../_services/top-alerts.service";
import { Utility } from "../../_helpers/utility";
import { PermissionsService } from "../../_services/permissions.service";
import { DialogService } from "../../_services/dialog.service";
import { MessagesService } from "../../_services/messages.service";
import {TransactionService} from '../../_services/transaction.service';

@Component({
  selector: "app-client-invoices",
  templateUrl: "./client-invoices.component.html",
  styleUrls: ["./client-invoices.component.css"],
})
export class ClientInvoicesComponent implements OnInit {
  @Input() isLightVersion = false;

  public host: string = environment.host;
  public listInvoices: any = [];
  public isLoading = false;
  public modalRef: NgbModalRef;
  public sortAndPagination: any = [];

  constructor(
    private http: HttpClient,
    private router: ActivatedRoute,
    private route: Router,
    private modalService: NgbModal,
    public userService: UserService,
    public dialogService: DialogService,
    public messages: MessagesService,
    public paginationService: PaginationService,
    public topAlertsService: TopAlertsService,
    public permissionsService: PermissionsService,
    public transService: TransactionService,
    public utility: Utility
  ) {}

  ngOnInit() {
    this.paginationService.sortField = "updated_at";
    this.paginationService.searchQuery = "";
    if (this.isLightVersion) {
      this.getListInvoices();
    }
  }
  getListInvoices(event: any = null) {
    const objRequest = {
      limit: !this.isLightVersion
        ? event
          ? event.pageSize
          : this.paginationService.pageSize
        : 100,
      page: !this.isLightVersion
        ? event
          ? event.pageNo
          : this.paginationService.pageNo
        : 1,
      search: !this.isLightVersion ? this.paginationService.searchQuery : "",
      orderby: !this.isLightVersion
        ? this.paginationService.sortField
        : "updated_at",
      direction: !this.isLightVersion ? this.paginationService.sortDir : "DESC",
      u_token: this.userService.getToken(),
    };
    let url = "/customer/check/invoice/list";
    if (
      this.userService.isClient() ||
      this.userService.isCustomer() ||
      this.isLightVersion
    ) {
      /*url = '/client/check/invoice/list';*/
      url = "/client/check/invoice/new/list";
    }

    this.http.get<any>(this.host + url, { params: objRequest }).subscribe(
      (response) => {
        if (response.success) {
          /*console.log(response);*/
          const a = [];
          if (this.isLightVersion) {
            response.list.data.forEach(function (item) {
              if (
                (item.status == "unpaid" && item.u_token != item.sndr_token) ||
                (item.status == "new" && item.u_token != item.sndr_token)
              ) {
                a.push(item);
              }
            });
            this.listInvoices = a;
            this.paginationService.visiblePagination = false;
          } else {
            this.listInvoices = <any>response.list.data;
          }
          this.paginationService.setParamsForResponce(response.list);
        }
      },
      (errResponse) => {
        if (errResponse.error) {
          this.isLoading = false;
          this.utility.getMessageError(errResponse.error);
          this.topAlertsService.popToast(
            "error",
            "Error",
            this.utility.errorMessage
          );
        }
      }
    );
  }

  canDecline(transactionOdj: any = null) {
    return (
      transactionOdj.status == "unpaid" &&
      transactionOdj.sndr_email.toLocaleLowerCase() ==
        this.userService.getEmail().toLocaleLowerCase()
    );
  }

  voidTransaction(transactionObj: any = null) {
    console.log(transactionObj);
    let url = "";
    const tokensArr = <any>[];
    let postObj = <any>{};

    url = "/customer/check/invoice/void";
    tokensArr.push({
      i_token: transactionObj.ci_token,
      c_token: transactionObj.c_token,
    });
    postObj = {
      u_token: transactionObj.u_token,
      id_invoices: tokensArr,
    };

    const objDataDialog = {
      title: "Please confirm your action",
      text: "Are you sure you want to decline invoice.",
      button_cancel_text: "Cancel",
      button_confirm_text: "Confirm & Decline",
      checkbox_confirm: false,
    };

    this.dialogService
      .openDialog(objDataDialog)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.http.post<any>(this.host + url, postObj).subscribe(
            (response) => {
              if (response.success) {
                this.topAlertsService.popToast(
                  "success",
                  "Success",
                  this.messages.get("INVOICE_DECLINE_SUCESSFULLY")
                );
                this.getListInvoices();
                window.location.reload();
              }
            },
            (errResponse) => {
              if (errResponse.error) {
                this.utility.getMessageError(errResponse.error);
                this.topAlertsService.popToast(
                  "error",
                  "Error",
                  this.utility.errorMessage
                );
              }
            }
          );
        }
      });
  }
}
