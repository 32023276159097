import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../auth.service';
import {UserService} from '../../_services/user.service';
import {JqueryService} from '../../_services/jquery.service';
import {Location} from '@angular/common';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.css']
})
export class LeftSidebarComponent implements OnInit {
  public menu: any = [];
  public objLink: any = environment.link;
  _jqueryService: any;
  // private counter: number = 0;

  constructor(
    public userService: UserService,
    private authenticationService: AuthenticationService,
    public jqueryService: JqueryService,
    public location: Location,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    // console.log(window.location.href.indexOf('/users/'));
  }

  winterIsComing() {
    return window.location.href.indexOf('/users/') >= 0;
  }

  getName() {
    return this.userService.getFullName();
  }

  getRoleName() {
    let strRole = '';
    switch (this.userService.getUserRole()) {
      case 'SuperAdmin':
      case 'Admin':
        strRole = 'Administrator';
        break;
      case '':
    }

    return strRole;
  }

  logout() {
    this.authenticationService.logout();
  }

  callAction(stringAction: string = '') {
    eval(stringAction);
  }

  checkAction(itemMenu: any) {
    if (itemMenu.clickAction) {
      return this.callAction(itemMenu.clickAction);
    } else {
      return this.router.navigateByUrl(itemMenu.sref);
    }
  }

  toogleMenu() {
    if( this.jqueryService.bShowToogleMenuSize ) {
      this.jqueryService.bToogleMenuSize = !this.jqueryService.bToogleMenuSize;
    }
    localStorage.setItem('main_menu_size', this.jqueryService.bToogleMenuSize ? 'menu-layout-mini' : 'menu-layout-compact' );
    this.jqueryService.os_init_main_menu();
    this.jqueryService.os_init_sub_menus();
  }

  checkAndRedirect() {
    if ( this.jqueryService.bToogleMenuSize && this.jqueryService.bShowToogleMenuSize ) {
      this.toogleMenu();
    } else {
      const strRedirect = this.userService.isMerchant() || this.userService.isCustomer() || this.userService.isDemoUser() ? '/transactions' : '/users';
      this.userService.redirectJustSimple(strRedirect);
    }
  }

}
