import { Injectable } from '@angular/core';
import { CountdownTimerService, countDownTimerConfigModel, countDownTimerTexts,
  countUpTimerConfigModel, timerTexts, CountupTimerService} from 'ngx-timer';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  public configCD: any;
  public isTimerRun = false;
  public direction = 'down';

  constructor(
    public countDownTimerService: CountdownTimerService,
    public countupTimerService: CountupTimerService
  ) { }

  initTimerCD( direction: string = '' ) {
    this.direction = direction;
    if ( 'down' ) {
      this.configCD = new countDownTimerConfigModel();

      // custom class
      this.configCD.timerClass = 'test_Timer_class';

      // timer text values
      this.configCD.timerTexts = new countDownTimerTexts();
      this.configCD.timerTexts.hourText = null;
      this.configCD.timerTexts.minuteText = null;
      this.configCD.timerTexts.secondsText = 'ss';
      this.countDownTimerService.onTimerStatusChange.subscribe( event => this.timerFinish(event) );
    } else {
      this.configCD = new countUpTimerConfigModel();

      // custom class
      this.configCD.timerClass  = 'test_Timer_class';

      // timer text values
      this.configCD.timerTexts = new timerTexts();
      this.configCD.timerTexts.hourText = '';
      this.configCD.timerTexts.minuteText = '';
      this.configCD.timerTexts.secondsText = '';
    }
  }

  setTime(intNumber: number = 0, type: string = 's') {
    this.isTimerRun = true;
    const cdate = new Date();
    switch (type) {
      case 's':
        cdate.setSeconds(cdate.getSeconds() + intNumber );
        break;
      case 'm':
        cdate.setMinutes(cdate.getMinutes() + intNumber );
        break;
      case 'h':
        cdate.setHours(cdate.getHours() + intNumber );
        break;
    }

    if ( this.direction == 'down' ) {
      this.countDownTimerService.startTimer(cdate);
    } else {
      this.countupTimerService.startTimer(cdate);
    }
  }

  timerFinish( event: any = null ): void {
    if ( event ) {
      this.isTimerRun = event == 'STOP' ? false : true;
    }
  }

  getRemainigTime() {
    if ( !this.isTimerRun ) {
      return '';
    }

    if ( this.direction == 'down' ) {
      return this.countDownTimerService.timerValue.seconds != '00' ? this.countDownTimerService.timerValue.seconds : '';
    } else {
      return this.countupTimerService.timerValue.seconds != '00' ? this.countupTimerService.timerValue.seconds : '';
    }
  }
}
