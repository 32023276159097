import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../auth.service';
import {MessagesService} from '../../_services/messages.service';
import {TopAlertsService} from '../../_services/top-alerts.service';
import {Utility} from '../../_helpers/utility';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.css']
})
export class RecoveryPasswordComponent implements OnInit {
  public loading = false;
  public model: any = {};
  public host: string;
  public tokenPassword: string;

  constructor(
    private http: HttpClient,
    private router: ActivatedRoute,
    private route: Router,
    private authenticationService: AuthenticationService,
    public messages: MessagesService,
    public topAlertsService: TopAlertsService,
    public utility: Utility
  ) {
    this.host = environment.host;
  }

  ngOnInit() {
    this.tokenPassword = this.router.snapshot.paramMap.get('token_password');
    console.log( this.tokenPassword );
  }
  resetPassword() {
    if ( this.model.password != this.model.cpassword ) {
      this.topAlertsService.popToast('info', '', this.messages.get('PASSWORD_DOES_NOT_MATCH_THE_CONFIRM_PASSWORD'));
      return;
    }
    this.authenticationService.resetPassword(this.tokenPassword, this.model.password, this.model.cpassword)
      .subscribe(
        result => {
          if ( result ) {
            if ( result.success ) {
              this.loading = false;
              this.topAlertsService.popToast('success', '', result.message);
            }
          }
        },
        errResponse => {
          this.loading = false;
          if ( errResponse.error.error ) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
          }
        }
      );
  }
}
