<div class="content-box">
  <div class="element-wrapper">
    <div class="element-box col-9" style="margin: auto;">
      <div class="thank-you-page">
        <div class="text-center">
          <a href="https://seamlesschex.com/?utm_source=Check_Link_Thank_You_Page&utm_medium=ThankYouPage&utm_campaign=Check_Link_Thank_You_Page">
            <img src="assets/img/Paynote_-_New_Blue.svg" style="max-height: 70px;">
          </a>
        </div>
        <hr>
        <!--<div class="row">-->
        <div class="col-md-9 row" style="margin: auto;float: none; max-width: 1000px; width: 100%;">
          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 wrap-success">
            <img src="/assets/img/check-accept-approve.svg">
          </div>
          <h1 class="text-center col-lg-10 col-md-10 col-sm-10 col-xs-12" style="margin-top: 20px;">
            {{getMessage()}}
            <!--Thank you!  Your payment was sent to <span id="merchant_name" style="font-weight: bold;">{{getMerchantName}}</span> using SeamlessChex!-->
          </h1>
          <div class="clear-block"></div>
          <br><br><br>
          <div class="col-lg-7 col-md-7 col-sm-7">
            <p style="color: #6699cc;font-size: 36px;line-height: 50px;">Are you a business owner or financial manager who's tired of credit card fees, late payments, or waiting for checks in the mail?</p>
            <br>
            <p style="font-size: 20px;">Eliminate all of these hassles with SeamlessChex TODAY!  With SeamlessChex you'll be able to:</p>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-5">
            <img src="/assets/img/checks-screenwhitehires.jpg">
          </div>
          <div class="clear-block"></div>
          <br>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <span style="height: 100px;">
              <img src="/assets/img/default-checkmark-round-plain-32px.png" class="image-ok">
            </span>
            <span class="list-points">Accept check payments in 60 seconds or less! <em>(using our state-of-the-art online check acceptance portal)</em></span>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <img src="/assets/img/default-checkmark-round-plain-32px.png" class="image-ok">
            <span class="list-points">Say goodbye to costly credit card fees!</span>
          </div>
          <div class="clear-block"></div>
          <br>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <img src="/assets/img/default-checkmark-round-plain-32px.png" class="image-ok">
            <span class="list-points">Accept and verify customers checks by phone, fax, or web with Seamless Chex, and never hear "Check is in the mail" again!</span>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <img src="/assets/img/default-checkmark-round-plain-32px.png" class="image-ok">
            <span class="list-points">Verify your clients check for account balance and validity (with the most advanced verification on the market!)</span>
          </div>
          <div class="clear-block"></div>
          <br>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <img src="/assets/img/default-checkmark-round-plain-32px.png" class="image-ok">
            <span class="list-points">Plug your clients check information into our easy-to-use interface and verify it instantly</span>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <img src="/assets/img/default-checkmark-round-plain-32px.png" class="image-ok">
            <span class="list-points">Send your client a link to a blank check and have them populate it instantly</span>
          </div>
          <div class="clear-block"></div>
          <br>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <img src="/assets/img/default-checkmark-round-plain-32px.png" class="image-ok">
            <span class="list-points">And much, much more!</span>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <!--    <img src="/assets/img/default-checkmark-round-plain-32px.png" class="image-ok">
                <span class="list-points">Send your client a link to a blank check and have them populate it instantly</span>-->
          </div>
          <div class="clear-block"></div>
          <br><br><br>
          <a href="https://seamlesschex.com/signup/?utm_source=Check_Link_Thank_You_Page&utm_medium=ThankYouPage&utm_campaign=Check_Link_Thank_You_Page"
             class="orange-button">
            <strong>Get Started For FREE Now &gt;&gt;</strong>
          </a>
          <div class="clear-block"></div>
          <br><br>
        </div>
        <!--</div>-->
        <div class="clear-block"></div>
        <div style="background: rgba(232,232,238,1);" class="row">
          <div class="col-lg-2"></div>
          <div class="col-lg-4 text-center">
            <br><br>
            <div style="border-style: none; border-radius: 24px; width: 75px;height: 85px;overflow: hidden; margin: auto;">
              <img src="/assets/img/Clifford-Parker.jpg">
            </div>
            <br>
            <strong><span style="font-family: arial, helvetica, sans-serif;">Clifford Parker</span></strong>
            <br>
            <p style="text-align: center;">
              <span style="font-size:14px;">
                <span style="color: rgb(105, 105, 105);">
                  <span style="font-family: arial, helvetica, sans-serif;">Owner, Parker Insurance</span>
                </span>
              </span>
            </p>
            <p class="lplh-22" style="text-align: center;">
              <span style="color: rgb(105, 105, 105);">
                <a href="http://www.parkerinsurance.com" target="_blank" style="color: rgb(105, 105, 105);text-decoration: underline;">
                  <span style="font-size:14px;">
                    <span style="font-family: arial,helvetica,sans-serif;">www.ParkerInsurance.com</span>              
                  </span>
                </a>
              </span>
            </p>
            <p class="lplh-26" style="text-align: center;">
              <span style="color: rgb(105, 105, 105);"><span style="font-size:16px;">
                  <em>“I like the Seamless Chex software! The Seamless Chex system is a very simple software to operate and was very easy to set up the first time. We use it regularly here in our agency. I can highly recommend this software to anyone interested in improving their check reception efficiencies.”&nbsp;</em></span></span>
            </p>
          </div>
          <div class="col-lg-4 text-center">
            <br><br>
            <div style="border-style: none; border-radius: 24px; width: 75px;height: 85px;overflow: hidden; margin: auto;">
              <img src="/assets/img/Jesse-Minc.jpg">
            </div>
            <br>
            <strong><span style="font-family: arial, helvetica, sans-serif;">Jesse Minc</span></strong>
            <br>
            <p style="text-align: center;">
              <span style="font-size:14px;">
                <span style="color: rgb(105, 105, 105);">
                  <span style="font-family: arial, helvetica, sans-serif;">Attorney, Rosenberg Minc Falkoff & Wolff LLP</span>
                </span>
              </span>
            </p>
            <p class="lplh-22" style="text-align: center;">
              <span style="color: rgb(105, 105, 105);">
                <a href="http://www.MedicalMalPractice.net" target="_blank" style="color: rgb(105, 105, 105);text-decoration: underline;">
                  <span style="font-size:14px;">
                    <span style="font-family: arial,helvetica,sans-serif;">www.MedicalMalPractice.net</span>              
                  </span>
                </a>
              </span>
            </p>
            <p class="lplh-26" style="text-align: center;">
              <span style="color: rgb(105, 105, 105);"><span style="font-size:16px;">
                  <em>“The importance of getting checks deposited quickly and securely is critical to our law firm. With Seamless Chex we know that we have the best software in place to allow our law firm to collect payment quickly and efficiently from our clients.”&nbsp;</em></span></span>
            </p>
          </div>
          <div class="col-lg-2"></div>
          <div class="clear-block"></div>
        </div>
        <div style="background: rgba(64,137,232,1);">
          <br><br>
          <p class="lplh-38" style="text-align: center;">
            <span style="color:#ffffff;"><span style="font-size: 36px; text-align: center;">Say Goodbye To Credit Card Fees &amp; Waiting For Checks!<br></span></span>
          </p>
          <div class="col-lg-7 text-center" style="margin: auto; float: none;">
            <a href="https://seamlesschex.com/signup/?utm_source=Check_Link_Thank_You_Page&utm_medium=ThankYouPage&utm_campaign=Check_Link_Thank_You_Page"
               class="orange-button">
              <strong>Get Started For FREE Now &gt;&gt;</strong>
            </a>
          </div>
          <div class="clear-block"></div>
          <br><br>
          <div class="clear-block"></div>
        </div>
        <br><br>
        <p class="text-center">
          <a href="https://seamlesschex.com/privacy-policy/">Privacy Policy</a>
          &nbsp; &nbsp;
          <a href="https://seamlesschex.com/terms-and-conditions/">Terms &amp; Conditions</a>
        </p>
        <p style="text-align: center;">
          <span style="color:#999999;">
            <span style="font-size: 13px; line-height: 17px;">
              <span style="font-family: arial,helvetica,sans-serif;">
                &nbsp;Copyright © 2015 Seamless Chex LLC. All rights reserved.
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
</div>
<!--<section class="content-header">
  <div class="text-center" style="padding-top: 30px;">
    <img src="/assets/img/check-accept-approve.svg" style="width: 100px;">
  </div>
  <h1 class="page-heading text-center" style="margin-top: 30px;    font-size: 35px;
    font-weight: bold;">Thank you!</h1>
  <h2 class="page-heading text-center" style="margin-top: 30px;">
    You payment was sent to <span id="merchant_name" style="font-weight: bold;"></span>.
  </h2>
  
</section>-->

<style>
  section {
    width: 500px;
    margin: auto;
    background-color: white;
    margin-top: 40px !important;
    height: 500px;
    border-radius: 5px;
    border: 1px solid black;
    /*box-shadow: 1px 1px 1px 1px black;*/
  }
  .login-page, .register-page { background-color: white; }
  .zopim { display: none; }
  .image-ok { float: left; margin-right: 10px; }
  .list-points { font-size: 20px;line-height: 30px; color: #696969; }
  .orange-button {
    display: block;
    border-style: solid;
    border-radius: 5px;
    z-index: 34;
    /*    width: 862px;
        height: 73px;*/
    background-color: #f7941d;
    background: -webkit-linear-gradient(#f7941d,#d75305);
    background: -moz-linear-gradient(#f7941d,#d75305);
    background: -ms-linear-gradient(#f7941d,#d75305);
    background: -o-linear-gradient(#f7941d,#d75305);
    background: linear-gradient(#f7941d,#d75305);
    box-shadow: inset 0px 1px 0px #ffbb6a, inset 0 -1px 2px #a33f03;
    text-shadow: 1px 1px #521601;
    color: #fff;
    border-width: 1px;
    border-color: #333333;
    font-size: 36px;
    line-height: 43px;
    font-weight: 400;
    font-family: Arial,Helvetica,sans-serif;
    font-style: normal;
    text-align: center;
    background-repeat: no-repeat;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }

  .orange-button:active {
    background-color: #de7312;
    background: -webkit-linear-gradient(#d75305,#d75305);
    background: -moz-linear-gradient(#d75305,#d75305);
    background: -ms-linear-gradient(#d75305,#d75305);
    background: -o-linear-gradient(#d75305,#d75305);
    background: linear-gradient(#d75305,#d75305);
    box-shadow: inset 0px 2px 4px #5e3007;
    color: #fff;
  }

  .orange-button:hover {
    background-color: #eb8016;
    background: -webkit-linear-gradient(#eb8016,#cc4504);
    background: -moz-linear-gradient(#eb8016,#cc4504);
    background: -ms-linear-gradient(#eb8016,#cc4504);
    background: -o-linear-gradient(#eb8016,#cc4504);
    background: linear-gradient(#eb8016,#cc4504);
    box-shadow: inset 0px 1px 0px #ffb164, inset 0 -1px 2px #993302;
    color: #fff;
  }

  .wrap-success img { max-height: 140px; }
  @media only screen and (max-width: 768px){
    /*.thank-you-page { font-size: 4vw; }*/
  }

  @media only screen and (max-width: 414px){
    .wrap-success img { max-height: 60px; }
    h1 { font-size: 22px; }
    .col-lg-7.col-md-7.col-sm-7 p:first-child {font-size: 20px !important;line-height: 26px !important; }
    .col-lg-7.col-md-7.col-sm-7 p:last-child {font-size: 16px !important; }

    .col-lg-6.col-md-6.col-sm-6 .list-points { 
      font-size: 14px;
      line-height: 20px;
      color: #696969;
      margin-bottom: 20px;
      display: -webkit-box; 
    }
  }
</style>