<ng-progress color="purple"></ng-progress>
<div class="all-wrapper menu-side with-pattern">
  <div class="auth-box-w">
    <div class="logo-w">
      <a href="/"><img alt="" src="assets/img/Paynote_-_New_Blue.svg"></a>
    </div>
    <div>
      <h4 class="auth-header">
        Reset Password
      </h4>
      <form name="form" (ngSubmit)="f.form.valid && resetPassword()" #f="ngForm">
        <div class="form-group" [ngClass]="{ 'has-error has-danger': !password.valid && (password.dirty || password.touched) }">
          <label for="recoveryPassword">New Password</label>
          <input id="recoveryPassword" name="password" class="form-control" placeholder="New Password" type="password" 
                 [(ngModel)]="model.password" #password="ngModel" required minlength="8">
          <div class="pre-icon os-icon os-icon-fingerprint"></div>
          <div *ngIf="!password.valid && (password.dirty || password.touched)" 
              class="help-block form-text with-errors form-control-feedback">
            <span *ngIf="password.errors.required">
              Password is required
            </span>
            <span *ngIf="password.errors.minlength">
              Password must be at least 8 characters long.
            </span>
          </div>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error has-danger': !cpassword.valid && (cpassword.dirty || cpassword.touched) }">
          <label for="confirmRecoveryPassword">Confirm New Password</label>
          <input id="confirmRecoveryPassword" name="cpassword" class="form-control" placeholder="Confirm New Password" type="password" 
                 [(ngModel)]="model.cpassword" #cpassword="ngModel" required>
          <div class="pre-icon os-icon os-icon-fingerprint"></div>
          <div *ngIf="!cpassword.valid && (cpassword.dirty || cpassword.touched)" 
              class="help-block form-text with-errors form-control-feedback">Confirm New Password</div>
        </div>
        <div class="buttons-w">
          <button [disabled]="loading || !f.form.valid" class="btn btn-primary" type="submit">Submit</button>
          <button class="btn btn-default pull-right" routerLink="/login" type="button">Login</button>
        </div>
        <br>
      </form>
    </div>
  </div>
</div>
