import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { MatDialog } from "@angular/material/dialog";
import { JqueryService } from "../../_services/jquery.service";
import { DialogService } from "../../_services/dialog.service";
import { TopAlertsService } from "../../_services/top-alerts.service";
import { Utility } from "../../_helpers/utility";
import { MessagesService } from "../../_services/messages.service";
import { Spinkit } from "ng-http-loader";

@Component({
  selector: "app-status-page",
  templateUrl: "./status-page.component.html",
  styleUrls: ["./status-page.component.css"],
})
export class StatusPageComponent implements OnInit {
  public spinkit = Spinkit;
  public host: string = environment.host;
  public listStatusApp: any = [];
  public isSecretTrue = true;
  public modalRef: NgbModalRef;
  public loading = false;
  public secret: any = {};
  public statusResponse = [
    {
      value: "dwolla",
      response: { success: false },
      src: "assets/img/brand/dwolla.jpg",
    },
    {
      value: "twilio",
      response: { success: false },
      src: "assets/img/brand/twilio-logo-red.svg",
    },
    {
      value: "max_mind",
      response: { success: false },
      src: "assets/img/brand/maxmind.png",
    },
    {
      value: "plaid",
      response: { success: false },
      src: "assets/img/brand/plaid-logo.png",
    },
    {
      value: "never_bounce",
      response: { success: false },
      src: "assets/img/brand/neverbounce.png",
    },
  ];
  public statusResponse2 = [
    {
      value: "transfers",
      response: { list: { hour_1: {}, hour_3: {}, hour_24: {} } },
    },
    {
      value: "dwolla_webhooks",
      response: { list: { hour_1: {}, hour_3: {}, hour_24: {} } },
    },
    {
      value: "user_login",
      response: { list: { hour_1: {}, hour_3: {}, hour_24: {} } },
    },
  ];
  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    public dialog: MatDialog,
    private jqueryService: JqueryService,
    public dialogService: DialogService,
    public topAlertsService: TopAlertsService,
    public messages: MessagesService,
    public utility: Utility
  ) {}

  ngOnInit() {
    this.jqueryService.initInterface();
  }

  getListStatusApp() {
    const objRequest = {
      key: this.secret.key,
    };
    /*↓↓↓ checking error ↓↓↓*/
    this.http
      .get<any>(this.host + "/monitor/test/dwolla", { params: objRequest })
      .subscribe(
        (response) => {
          if (response == null) {
            this.topAlertsService.popToast(
              "error",
              "Error",
              "Invalid secret key"
            );
          }
        },
        (errResponse) => {
          if (
            errResponse.error ||
            errResponse.error == null ||
            errResponse == null
          ) {
            this.topAlertsService.popToast(
              "error",
              "Error",
              "Invalid secret key"
            );
          }
        }
      );
    /*↑↑↑ checking error ↑↑↑*/
    for (let i = 0; i <= 4; i++) {
      this.http
        .get<any>(this.host + "/monitor/test/" + this.statusResponse[i].value, {
          params: objRequest,
        })
        .subscribe((response: any) => {
          if (response) {
            this.isSecretTrue = false;
            this.statusResponse[i].response = response;
          }
        });
    }
    for (let i = 0; i <= 2; i++) {
      this.http
        .get<any>(
          this.host + "/monitor/test/" + this.statusResponse2[i].value,
          { params: objRequest }
        )
        .subscribe((response: any) => {
          if (response) {
            this.isSecretTrue = false;
            this.statusResponse2[i].response = response;
            console.log(response);
          }
        });
    }
  }
}
