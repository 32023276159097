import {Component, OnInit} from '@angular/core';

import {AuthenticationService} from '../../auth.service';
import {UserService} from '../../_services/user.service';
import {JqueryService} from '../../_services/jquery.service';
import {MessagesService} from '../../_services/messages.service';
import {TopAlertsService} from '../../_services/top-alerts.service';
import {Utility} from '../../_helpers/utility';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent implements OnInit {
  model: any = {};
  loading = false;

  constructor(
    private authenticationService: AuthenticationService,
    public userService: UserService,
    public messages: MessagesService,
    public jqueryService: JqueryService,
    public topAlertsService: TopAlertsService,
    public utility: Utility
  ) {}

  ngOnInit() {
    this.jqueryService.removeSelector('.modal-backdrop.fade');
    this.jqueryService.removeSelector('.modal.fade.show.d-block');
  }
  resetPassword() {
    this.loading = true;
    this.authenticationService.recoveryPassword(this.model.emailPassword )
      .subscribe(
        result => {
          if ( result ) {
            this.topAlertsService.popToast('success', '', result.message);
            this.loading = false;
          }
        },
        errResponse => {
          this.loading = false;
          if ( errResponse.error ) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);

          }
        }
      );
  }
}

