<span style="display: none"
  ><countdown-timer
    *ngIf="timerService.isTimerRun"
    [countDownTimerConfig]="timerService.configCD"
  ></countdown-timer
></span>
<div
  aria-hidden="true"
  class="password-setup-modal modal fade animated"
  role="dialog"
  tabindex="-1"
  [ngClass]="{ 'show-on-load': isShowOnLoad() }"
>
  <div class="modal-dialog modal-centered" role="document">
    <div class="modal-content modal-blue-bg onboarding-content">
      <div class="modal-header" style="padding: 20px 0">
        <button
          aria-label="Close"
          class="close"
          data-dismiss="modal"
          type="button"
          *ngIf="!canCloseModal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form
        name="form"
        autocomplete="nope"
        (ngSubmit)="f.form.valid && setupPasswordPhoneRegister()"
        [formGroup]="setupPasswordForm"
        (keydown)="setupPasswordPhoneRegister($event)"
        #f="ngForm"
        class="form-validation"
      >
        <div class="modal-body">
          <div class="row">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xl-12"
              style="margin-top: 7px"
            >
              <h4 class="modal-title text-center">Secure you Account</h4>
              <p class="text-center">
                Enter your mobile phone number, and click the link sent to your inbox to create the password
              </p>
<!--              <h6>Password Requirements:</h6>-->
<!--              <ul>-->
<!--                <li>Have at least 8 characters</li>-->
<!--                <li>Have at least one lower case letter</li>-->
<!--                <li>Have at least one capital letter</li>-->
<!--                <li>Have at least one number</li>-->
<!--              </ul>-->
            </div>
            <br />
<!--            <div class="col-sm-6">-->
<!--              <div-->
<!--                class="form-group"-->
<!--                [ngClass]="{-->
<!--                  'has-error has-danger':-->
<!--                    setupPasswordForm.get('password').errors &&-->
<!--                    (setupPasswordForm.get('password').dirty ||-->
<!--                      setupPasswordForm.get('password').touched)-->
<!--                }"-->
<!--              >-->
<!--                <label for="">Password</label>-->
<!--                <input-->
<!--                  name="password"-->
<!--                  id="password"-->
<!--                  class="form-control password"-->
<!--                  placeholder="Password"-->
<!--                  type="password"-->
<!--                  formControlName="password"-->
<!--                  required-->
<!--                  autocomplete="nope"-->
<!--                />-->
<!--                &lt;!&ndash;<div class="pre-icon os-icon os-icon-fingerprint"></div>&ndash;&gt;-->
<!--                <div-->
<!--                  *ngIf="-->
<!--                    setupPasswordForm.get('password').errors &&-->
<!--                    (setupPasswordForm.get('password').dirty ||-->
<!--                      setupPasswordForm.get('password').touched)-->
<!--                  "-->
<!--                  class="help-block form-text with-errors form-control-feedback"-->
<!--                >-->
<!--                  <span-->
<!--                    *ngIf="setupPasswordForm.get('password').errors.minLength"-->
<!--                  >-->
<!--                    Password must be at least 8 characters long.-->
<!--                  </span>-->
<!--                  <span-->
<!--                    *ngIf="setupPasswordForm.get('password').errors.required"-->
<!--                  >-->
<!--                    Password is required-->
<!--                  </span>-->
<!--                </div>-->
<!--                <ng9-password-strength-bar-->
<!--                  [passwordToCheck]="setupPasswordForm.value.password"-->
<!--                  [barLabel]="barLabel"-->
<!--                  [barColors]="myColors"-->
<!--                >-->
<!--                </ng9-password-strength-bar>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-sm-6">-->
<!--              <div-->
<!--                class="form-group"-->
<!--                [ngClass]="{-->
<!--                  'has-error has-danger':-->
<!--                    setupPasswordForm.get('cpassword').errors &&-->
<!--                    (setupPasswordForm.get('cpassword').dirty ||-->
<!--                      setupPasswordForm.get('cpassword').touched)-->
<!--                }"-->
<!--              >-->
<!--                <label for="cpassword">Confirm Password</label>-->
<!--                <input-->
<!--                  name="cpassword"-->
<!--                  class="form-control"-->
<!--                  placeholder="Confirm Password"-->
<!--                  type="password"-->
<!--                  id="cpassword"-->
<!--                  formControlName="cpassword"-->
<!--                  required-->
<!--                  autocomplete="off"-->
<!--                  validateEqual="password"-->
<!--                />-->
<!--                &lt;!&ndash;<div class="pre-icon os-icon os-icon-fingerprint"></div>&ndash;&gt;-->
<!--                <div-->
<!--                  *ngIf="-->
<!--                    setupPasswordForm.get('cpassword').errors &&-->
<!--                    (setupPasswordForm.get('cpassword').dirty ||-->
<!--                      setupPasswordForm.get('cpassword').touched)-->
<!--                  "-->
<!--                  class="help-block form-text with-errors form-control-feedback"-->
<!--                >-->
<!--                  Password mismatch-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <div class="col-sm-6" *ngIf="!isPhoneDisabled">
              <div
                class="form-group"
                [ngClass]="{
                  'has-error has-danger':
                    setupPasswordForm.get('phone').errors &&
                    (setupPasswordForm.get('phone').dirty ||
                      setupPasswordForm.get('phone').touched)
                }"
              >
                <label for="phone">Phone number</label>
                <input
                  class="form-control"
                  placement="top"
                  ngbTooltip="Phone number"
                  placeholder="e.g.(489) 797-3417"
                  type="text"
                  name="phone"
                  autocomplete="off"
                  id="phone"
                  formControlName="phone"
                  [textMask]="{ mask: maskPhone }"
                />
                <div
                  *ngIf="
                    setupPasswordForm.get('phone').errors &&
                    (setupPasswordForm.get('phone').dirty ||
                      setupPasswordForm.get('phone').touched)
                  "
                  class="help-block form-text with-errors form-control-feedback"
                >
                  <span
                    *ngIf="setupPasswordForm.get('phone').errors.validPhone"
                  >
                    Phone must be at least 10 digits long.
                  </span>
                  <span *ngIf="setupPasswordForm.get('phone').errors.required">
                    Phone number is required
                  </span>
                </div>
              </div>
<!--            </div>-->
<!--            <div class="col-sm-6" *ngIf="!isPhoneDisabled">-->
<!--              <div-->
<!--                class="form-group"-->
<!--                [ngClass]="{-->
<!--                  'has-error has-danger':-->
<!--                    setupPasswordForm.get('phone_pin').errors &&-->
<!--                    (setupPasswordForm.get('phone_pin').dirty ||-->
<!--                      setupPasswordForm.get('phone_pin').touched)-->
<!--                }"-->
<!--                [hidden]="!objDigitCheck.verifyCodeSend"-->
<!--              >-->
<!--                <label>Verification Code</label>-->
<!--                <input-->
<!--                  class="form-control"-->
<!--                  placeholder="e.g.1234"-->
<!--                  type="text"-->
<!--                  name="phone_pin"-->
<!--                  id="phone_pin"-->
<!--                  formControlName="phone_pin"-->
<!--                  required-->
<!--                  maxlength="4"-->
<!--                  minlength="4"-->
<!--                  appNumbers-->
<!--                  autocomplete="off"-->
<!--                />-->
<!--                <div-->
<!--                  *ngIf="-->
<!--                    setupPasswordForm.get('phone_pin').errors &&-->
<!--                    (setupPasswordForm.get('phone_pin').dirty ||-->
<!--                      setupPasswordForm.get('phone_pin').touched)-->
<!--                  "-->
<!--                  class="help-block form-text with-errors form-control-feedback"-->
<!--                >-->
<!--                  <span-->
<!--                    *ngIf="setupPasswordForm.get('phone_pin').errors.required"-->
<!--                  >-->
<!--                    Verification Code is required-->
<!--                  </span>-->
<!--                  <span-->
<!--                    *ngIf="setupPasswordForm.get('phone_pin').errors.minlength"-->
<!--                  >-->
<!--                    Verification Code must be at least 4 digits long.-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <button-->
<!--                style="-->
<!--                  color: #1071ef;-->
<!--                  text-decoration: underline;-->
<!--                  font-size: 12px;-->
<!--                  padding: 0;-->
<!--                "-->
<!--                type="button"-->
<!--                class="btn btn-link"-->
<!--                (click)="sendVerifyCode()"-->
<!--                *ngIf="objDigitCheck.verifyCodeSend"-->
<!--                [disabled]="timerService.isTimerRun"-->
<!--              >-->
<!--                Re-send Code&nbsp;<span-->
<!--                  [innerHTML]="timerService.getRemainigTime()"-->
<!--                ></span>-->
<!--              </button>-->
            </div>
<!--            <div-->
<!--              class="col-sm-12"-->
<!--              style="margin-bottom: 15px; margin-top: 10px"-->
<!--              *ngIf="availableSignUpByEmal()"-->
<!--            >-->
              <!-- <p class="manual-verification__header__text text-center" style="margin-bottom: 5px;">
                How would you like to receive your verification code?
              </p> -->
<!--              <div class="d-flex">-->
<!--                <button-->
<!--                  class="mr-3 mb-3 btn btn-primary"-->
<!--                  type="button"-->
<!--                  style="display: inline-block; margin: auto !important"-->
<!--                  (click)="sendVerifyTextCode()"-->
<!--                  [disabled]="checkValidButtonSendPineCode()"-->
<!--                >-->
<!--                  Send Verification Code&nbsp;<span-->
<!--                    [innerHTML]="timerService.getRemainigTime()"-->
<!--                  ></span>-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div-->
<!--              class="col-sm-12"-->
<!--              style="margin-bottom: 15px; margin-top: 10px"-->
<!--              *ngIf="!availableSignUpByEmal()"-->
<!--            >-->
<!--              <p-->
<!--                class="manual-verification__header__text text-center"-->
<!--                *ngIf="isPhoneDisabled"-->
<!--                style="margin-bottom: 5px"-->
<!--              >-->
<!--                Please select Text Message or Phone Call and verify your phone-->
<!--                <b>{{ userService.getMaskedPhone() }}</b>-->
<!--              </p>-->
<!--              <p-->
<!--                class="manual-verification__header__text text-center"-->
<!--                style="margin-bottom: 5px"-->
<!--              >-->
<!--                How would you like to receive your verification code?-->
<!--              </p>-->
<!--              <div class="d-flex">-->
<!--                <button-->
<!--                  class="mr-3 mb-3 btn btn-primary"-->
<!--                  type="button"-->
<!--                  style="display: inline-block; margin: auto !important"-->
<!--                  (click)="sendVerifyTextCode()"-->
<!--                  [disabled]="checkValidButtonSendPineCode()"-->
<!--                >-->
<!--                  Text Message&nbsp;<span-->
<!--                    [innerHTML]="timerService.getRemainigTime()"-->
<!--                  ></span>-->
<!--                </button>-->
<!--                <button-->
<!--                  class="mr-3 mb-3 btn btn-primary"-->
<!--                  type="button"-->
<!--                  style="display: inline-block; margin: auto !important"-->
<!--                  (click)="sendVerifyVoiceCode()"-->
<!--                  [disabled]="checkValidButtonSendPineCode()"-->
<!--                >-->
<!--                  Phone Call-->
<!--                  <i-->
<!--                    aria-hidden="true"-->
<!--                    class="fa fa-question-circle"-->
<!--                    placement="top"-->
<!--                    ngbTooltip="If you have a robo call blocker on your landline this option will not work"-->
<!--                  >-->
<!--                  </i>-->
<!--                  &nbsp;<span-->
<!--                    [innerHTML]="timerService.getRemainigTime()"-->
<!--                  ></span>-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-sm-6" *ngIf="isPhoneDisabled">-->
<!--              <div-->
<!--                class="form-group"-->
<!--                [ngClass]="{-->
<!--                  'has-error has-danger':-->
<!--                    setupPasswordForm.get('phone_pin').errors &&-->
<!--                    (setupPasswordForm.get('phone_pin').dirty ||-->
<!--                      setupPasswordForm.get('phone_pin').touched)-->
<!--                }"-->
<!--                [hidden]="!objDigitCheck.verifyCodeSend"-->
<!--              >-->
<!--                <label>Verification Code</label>-->
<!--                <input-->
<!--                  class="form-control"-->
<!--                  placeholder="e.g.1234"-->
<!--                  type="text"-->
<!--                  name="phone_pin"-->
<!--                  id="phone_pin"-->
<!--                  formControlName="phone_pin"-->
<!--                  required-->
<!--                  maxlength="4"-->
<!--                  minlength="4"-->
<!--                  appNumbers-->
<!--                  autocomplete="off"-->
<!--                />-->
<!--                <div-->
<!--                  *ngIf="-->
<!--                    setupPasswordForm.get('phone_pin').errors &&-->
<!--                    (setupPasswordForm.get('phone_pin').dirty ||-->
<!--                      setupPasswordForm.get('phone_pin').touched)-->
<!--                  "-->
<!--                  class="help-block form-text with-errors form-control-feedback"-->
<!--                >-->
<!--                  <span-->
<!--                    *ngIf="setupPasswordForm.get('phone_pin').errors.required"-->
<!--                  >-->
<!--                    Verification Code is required-->
<!--                  </span>-->
<!--                  <span-->
<!--                    *ngIf="setupPasswordForm.get('phone_pin').errors.minlength"-->
<!--                  >-->
<!--                    Verification Code must be at least 4 digits long.-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <button-->
<!--                style="-->
<!--                  color: #1071ef;-->
<!--                  text-decoration: underline;-->
<!--                  font-size: 12px;-->
<!--                  padding: 0;-->
<!--                "-->
<!--                type="button"-->
<!--                class="btn btn-link"-->
<!--                (click)="sendVerifyCode()"-->
<!--                *ngIf="objDigitCheck.verifyCodeSend"-->
<!--                [disabled]="timerService.isTimerRun"-->
<!--              >-->
<!--                Re-send Code&nbsp;<span-->
<!--                  [innerHTML]="timerService.getRemainigTime()"-->
<!--                ></span>-->
<!--              </button>-->
<!--            </div>-->
          </div>
          <br />
        </div>
        <br /><br />
        <div class="modal-footer">
          <button
            aria-label="Close"
            class="slick-prev slick-arrow"
            data-dismiss="modal"
            type="button"
          >
            Create Password Later
          </button>
          <button
            [disabled]="!this.setupPasswordForm.get('phone').valid"
            class="btn btn-link slick-next slick-arrow"
            type="submit"
          >
            Submit & Send Link
          </button>
        </div>
      </form>
    </div>
  </div>
  <!--</div>-->
</div>
