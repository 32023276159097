import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NumbersDirective } from "../../_helpers/utility";
import { DigitsDirective } from "../../_directives/digits.directive";
import { TrimDirective } from "../../_directives/trim.directive";
import { SpecialCharacterDirective } from "../../_directives/special-character-directive.directive";
import { PhonePipe } from "../../_helpers/utility";
import { OnlyLettersDirective } from "../../_directives/onlyLetters.directive";
import { TruncatePipe } from "../../_pipes/truncate.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [
    NumbersDirective,
    DigitsDirective,
    SpecialCharacterDirective,
    TrimDirective,
    PhonePipe,
    TruncatePipe,
    OnlyLettersDirective,
  ],
  exports: [
    NumbersDirective,
    DigitsDirective,
    SpecialCharacterDirective,
    TrimDirective,
    PhonePipe,
    TruncatePipe,
    OnlyLettersDirective,
    TruncatePipe,
  ],
})
export class DirectivesModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: DirectivesModule,
    };
  }
}
