import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Utility} from '../../_helpers/utility';
import {PaginationService} from '../../_services/pagination.service';
import {UserService} from '../../_services/user.service';
import {MessagesService} from '../../_services/messages.service';
import {DialogService} from '../../_services/dialog.service';
import {TransactionService} from '../../_services/transaction.service';
import {CurrencyPipe} from '@angular/common';
import {JqueryService} from '../../_services/jquery.service';
import {TopAlertsService} from '../../_services/top-alerts.service';
import * as moment from 'moment';
import {PermissionsService} from '../../_services/permissions.service';

@Component({
  selector: 'app-accept-checks',
  templateUrl: './accept-checks.component.html',
  styleUrls: ['./accept-checks.component.css']
})
export class AcceptChecksComponent implements OnInit {

  @Input() merchantObject: any = {};
  @Input() isLightVersionAC = false;

  public objUser: any = {};
  public data: Date;
  public dashboardTrendsInfo: any = {};
  public lstSendChecks: any = [];
  public sortAndPagination: any = [];
  public checkPopupInfo: any = {
    account_bank: {},
    bank_info: {},
    recipient: {},
    sender: {},
    sender_info: {
      bank_info: {}
    }
  };
  public objFilter: any = {
    type: 'check',
    status: 'unpaid',
    /*type: 'All',
    status: 'All',*/
    recurring: 'All',
    transfer_speed: 'All',
    // date_start: '',
    // date_end: '',
    period: '',
    // interval: 'last_month',
    revenue: 0
  };
  public failedPopupInfo: any = {};
  public host: string;
  public errorMessage: string;
  public isError: boolean;
  public isModalError: boolean;
  public successMessage: string;
  public isSuccess: boolean;
  public errorModalMessage: string;
  public sortField: string;
  public sortDir: string;
  public listTransaction: any = [];
  public pageSize: any;
  public pageNo: any;
  public modalRef: NgbModalRef;
  public selectAllChecks = false;
  public canVoidCheck = false;
  public isLoading = false;
  public _dwl_token: string;
  public _u_token: string;
  public isAdminControl = false;
  public storageFilterName = 'transactionsList';
  public tz = this.userService.getTimeZone();

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    public utility: Utility,
    public paginationService: PaginationService,
    public userService: UserService,
    public messages: MessagesService,
    public dialogService: DialogService,
    public transService: TransactionService,
    private currencyPipe: CurrencyPipe,
    private router: ActivatedRoute,
    private jqueryService: JqueryService,
    public topAlertsService: TopAlertsService,
    public permissionsService: PermissionsService,
  ) {
    this.host = environment.host;
  }

  ngOnInit() {
    this.jqueryService.initInterface();
    this._dwl_token = this.router.snapshot.paramMap.get('dwl_token');
    this._u_token = this.router.snapshot.paramMap.get('u_token');
    this._dwl_token && this._u_token ? this.isAdminControl = true : this.isAdminControl = false;
/*  this.paginationService.sortField = 'created';
    this.paginationService.searchQuery = '';
    this.paginationService.pageSize = 50;*/
    // this.objFilter.date_start = moment().subtract(29, 'days').format('YYYY-MM-DD');
    // this.objFilter.date_end = moment().format('YYYY-MM-DD');
    this.listSendChecksTransactions();
  }

  sendCheckInfoPopup(content: any, fs_obj: any) {
    if (this.userService.isMerchant() || this.userService.isClient()) {
      this.checkPopupInfo = fs_obj;
      this.checkPopupInfo.bank_info = {};
      this.checkPopupInfo.account_bank = {};
      if (fs_obj.status === 'unpaid' || !fs_obj.dwl_fs_token) {
        this.getBunkInfoForDialog(fs_obj);
      }
    }
    if (this.userService.isAdmin() || this.userService.isSuperAdmin()) {
      this.checkPopupInfo = fs_obj;
      this.getBunkInfoForReciever(fs_obj);
      this.getBunkInfoForSender(fs_obj);
    }
    this.modalRef = this.modalService.open(content);
  }

  getRoutingNumber() {
    if ((this.userService.isMerchant() || this.userService.isClient()) && this.checkPopupInfo.account_bank) {
      return this.checkPopupInfo.account_bank.routing;
    }
    if ((this.userService.isAdmin() || this.userService.isSuperAdmin()) && this.checkPopupInfo.sender_info) {
      return this.checkPopupInfo.sender_info.account ? this.checkPopupInfo.sender_info.account.routing : '';
    }
  }

  getAccountNumber() {
    if ((this.userService.isMerchant() || this.userService.isClient()) && this.checkPopupInfo.account_bank) {
      return this.checkPopupInfo.account_bank.number;
    }
    if ((this.userService.isAdmin() || this.userService.isSuperAdmin()) && this.checkPopupInfo.sender_info) {
      return this.checkPopupInfo.sender_info.account ? this.checkPopupInfo.sender_info.account.mask: '';
    }
  }

  isReccuring(checkType: string = '') {
    return checkType.indexOf('recurring') >= 0;
  }

  closeModal() {
    if (!this.modalRef) {
      return;
    }
    this.modalRef.close();
  }

  getAmountString(objTransaction: any = null) {
    let strAmount = '';
    if (!objTransaction) {
      return '';
    }
    if (this.userService.isMerchant()) {
      if (objTransaction.type === 'check' && this.userService.getToken() !== objTransaction.rec_token
        && this.userService.getEmail() !== objTransaction.rec_email) {
        strAmount += '-';
      }
      if (objTransaction.type === 'invoice' && this.userService.getToken() === objTransaction.sndr_token
        && this.userService.getEmail() === objTransaction.sndr_email) {
        strAmount += '-';
      }
    }
    if (this.userService.isClient()) {
      if (this.userService.getToken() !== objTransaction.rec_token && this.userService.getEmail() !== objTransaction.rec_email) {
        strAmount += '-';
      }
    }

    strAmount += this.currencyPipe.transform(objTransaction.amount, '', 'symbol');

    return strAmount;
  }

  applySort(sortFieldName: string, sortsDir: string) {
    if (sortFieldName === this.paginationService.sortField) {
      if (sortsDir === 'DESC') {
        this.paginationService.sortDir = 'ASC';
        this.paginationService.sortIcons = false;
      } else {
        this.paginationService.sortDir = 'DESC';
        this.paginationService.sortIcons = true;
      }
    }
    this.paginationService.sortField = sortFieldName;
    this.paginationService.pageSize = this.pageSize;
    this.paginationService.pageNo = this.pageNo;
    this.listSendChecksTransactions(null);
  }

  unCheckedChecks(event: any) {
    event.stopPropagation();
    this.selectAllChecks = false;

    let canVoid = false;
    setTimeout(() =>
        this.lstSendChecks.forEach(function (check: any) {
          if (check.multiple) {
            canVoid = true;
          }
        })
      , 200);

    setTimeout(() => this.canVoidCheck = canVoid, 300);

  }

  resendNotificationCheck(invoice: any) {
    this.isLoading = true;
    const resendCheckArr = <any>[];
    resendCheckArr.push(invoice.pl_token);
    const objDataDialog = {
      title: 'Please confirm',
      text: this.messages.get('RESENT_NOTIFICATION_CONFIRM_TEXT'),
      button_cancel_text: 'Cancel',
      button_confirm_text: this.utility.getMessageUsability('confirm_resend_notification'),
      checkbox_confirm: true,
      checkbox_confirm_text: 'Please confirm',
    };
    this.dialogService.openDialog(objDataDialog).afterClosed().subscribe(result => {
      if (result) {
        this.http.post<any>(this.host + '/dwl/customer/payment-link/resend', {tokens: resendCheckArr})
          .subscribe(
            response => {
              if (response.success) {
                this.topAlertsService.popToast('success', 'Success', this.messages.get('RESENT_NOTIFICATION_EMAIL'));
                this.isLoading = false;
                scrollTo(0, 20);
              }
            },
            errResponse => {
              if (errResponse.error) {
                this.utility.getMessageError(errResponse.error);
                this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
              }
            }
          );
      }
    });
  }

  resendNotificationInvoice(invoice: any) {
    this.isLoading = true;

    const objDataDialog = {
      title: 'Please confirm',
      text: this.messages.get('RESENT_NOTIFICATION_CONFIRM_TEXT'),
      button_cancel_text: 'Cancel',
      button_confirm_text: this.utility.getMessageUsability('confirm_resend_notification'),
      checkbox_confirm: true,
      checkbox_confirm_text: 'Please confirm',
    };
    this.dialogService.openDialog(objDataDialog).afterClosed().subscribe(result => {
      if (result) {
        let objRequest: any;
        objRequest = {
          i_token: invoice.ci_token
        };
        this.http.get<any>(this.host + '/customer/check/invoice/resend', {params: objRequest})
          .subscribe(
            response => {
              if (response.success) {
                this.topAlertsService.popToast('success', 'Success', this.messages.get('RESENT_NOTIFICATION_EMAIL'));
                this.isLoading = false;
                scrollTo(0, 20);
              }
            },
            errResponse => {
              if (errResponse.error) {
                this.isLoading = false;
                this.utility.getMessageError(errResponse.error);
                this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
              }
            }
          );
      } else {
        this.isLoading = false;
      }
    });
  }

  getLinkForTransactionsList() {
    let url;
    if (this.merchantObject.role === 'Merchant' && this.isAdminControl) {
      url = '/check/customer/list';
    }
    if (this.merchantObject.role === 'Client' && this.isAdminControl) {
      url = '/check/client/list';
    }
    if (this.userService.isMerchant()) {
      url = '/check/customer/list';
    }
    if (this.userService.isCustomer() || this.isLightVersionAC) {
      url = '/check/client/list';
    }
    if (this.userService.isAdmin() && !this.isAdminControl || this.userService.isSuperAdmin() && !this.isAdminControl) {
      url = '/check/all/list';
    }

    return url;
  }

  listSendChecksTransactions(event: any = null, bResetPages: boolean = false, bGetCsvData: boolean = false) {
    this.selectAllChecks = false;
    this.pageSize = event ? event.pageSize : this.paginationService.pageSize;
    this.pageNo = event ? event.pageNo : this.paginationService.pageNo;
    const uToken = this.userService.getToken();
    let objRequest = {};
    if (this.isLightVersionAC) {
      objRequest = {
        limit: 100,
        page: 1,
        search: '',
        orderby: 'created',
        direction: 'DESC',
        u_token: this.userService.getToken(),
        // days_ago: this.objFilter.period,
        tz: this.tz,
        bell: 1
      };
    } else {
      objRequest = {
        limit: event ? event.pageSize : this.paginationService.pageSize,
        page: event ? event.pageNo : this.paginationService.pageNo,
        search: this.paginationService.searchQuery,
        orderby: this.paginationService.sortField,
        direction: this.paginationService.sortDir,
        u_token: this.userService.getToken(),
        days_ago: this.objFilter.period,
        tz: this.tz
      };
    }
    const url = this.getLinkForTransactionsList();
    objRequest = Object.assign(objRequest, this.objFilter);

      this.http.get<any>(this.host + url, {params: objRequest})
        .subscribe(
          response => {
            if (response.success) {
              this.lstSendChecks = response.list.data;
              const listSendChecksData =  this.lstSendChecks;
              if (this.isLightVersionAC) {
                this.lstSendChecks.forEach(function (item, i) {
                  if (item.sndr_token == uToken) {
                    listSendChecksData.splice(i, 1);
                  }
                });
                this.lstSendChecks = listSendChecksData;
              }
              this.dashboardTrendsInfo = response.trends;
              if (response.list.data.length) {
                this.paginationService.setParamsForResponce(response.list);
              } else {
                this.paginationService.itemsCount = 0;
              }

              this.paginationService.generateFilterForLocalStorage(this.storageFilterName, this.objFilter );
            }
          },
          errResponse => {
            if (errResponse.error) {
              this.utility.getMessageError(errResponse.error);
              this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
            }
          }
        );
  }

  canAcceptFunds(transactionOdj: any = null) {
    return transactionOdj.status == 'unpaid' && transactionOdj.pl_token &&
      transactionOdj.rec_email.toLocaleLowerCase() == this.userService.getEmail().toLocaleLowerCase() && this.userService.isHaveBankAccount();
  }
  canAcceptFundsByLink(transactionOdj: any = null) {
    return transactionOdj.status == 'unpaid' && transactionOdj.pl_token &&
      transactionOdj.rec_email.toLocaleLowerCase() == this.userService.getEmail().toLocaleLowerCase() && !this.userService.isHaveBankAccount();
  }

  getAcceptFundsByLink(transactionOdj: any = null) {
    return environment.hostOriginal + '/check/' + transactionOdj.pl_token;
  }

  canInvoicePay(transactionOdj: any = null) {
    return transactionOdj.status == 'unpaid' && (transactionOdj.type == 'invoice' || transactionOdj.type == 'recurring (invoice)') &&
      transactionOdj.sndr_email.toLocaleLowerCase() == this.userService.getEmail().toLocaleLowerCase() && this.userService.isHaveVerifyBankAccount();
  }
  canViewCheckInfo(transactionOdj: any = null) {
    return !( (transactionOdj.type == 'invoice' || transactionOdj.type == 'recurring (invoice)') && transactionOdj.status == 'unpaid' );
  }

  canResendNotification(transactionOdj: any = null, transactionType: string = null) {
    if (transactionType == 'internal') {
      return false;
    }
    if ( this.userService.isClient() ) {
      return false;
    }
    if (transactionType == 'check') {
      return transactionOdj.status == 'unpaid'
        && (transactionOdj.type == 'check' || transactionOdj.type == 'recurring (check)')
        && transactionOdj.sndr_email.toLocaleLowerCase() == this.userService.getEmail().toLocaleLowerCase();
    }
    if ( transactionType == 'invoice' ) {
      return transactionOdj.status == 'unpaid'
        && (transactionOdj.type == 'invoice' || transactionOdj.type == 'recurring (invoice)')
        && transactionOdj.rec_email.toLocaleLowerCase() == this.userService.getEmail().toLocaleLowerCase();
    }
  }

  canViewFeesOnCheck(transactionOdj: any = null) {
    if ( this.userService.isAdmin() || this.userService.isSuperAdmin() ) {
      return true;
    }
    if ( transactionOdj.type == 'invoice' || transactionOdj.type == 'recurring (invoice)' ) {
      return transactionOdj.sndr_email.toLocaleLowerCase() != this.userService.getEmail().toLocaleLowerCase();
    }
    if ( transactionOdj.type == 'check' || transactionOdj.type == 'recurring (check)' ) {
      return transactionOdj.rec_email.toLocaleLowerCase() != this.userService.getEmail().toLocaleLowerCase();
    }
  }

  acceptTransactionCheck(transactionOdj: any) {
    if (!this.userService.checkAvailableActions('payAcceptTransfer')) {
      return;
    }
    const objDataDialog = {
      title: 'Please confirm your action',
      text: 'Are you sure you want to accept transaction?',
      button_cancel_text: 'Cancel',
      button_confirm_text: this.utility.getMessageUsability('confirm_and_accept'),
      checkbox_confirm: false,
    };
    this.dialogService.openDialog(objDataDialog).afterClosed().subscribe(result => {
      if (result) {
        this.http.post<any>(this.host + '/dwl/client/payment-link/accept', {
          pl_token: transactionOdj.pl_token,
          u_token: this.userService.getToken()
        })
          .subscribe(
            response => {
              if (response.success) {
                this.topAlertsService.popToast('success', 'Success', this.messages.get('TRANSACTION_ACCEPTED_SUCESSFULLY'));
                this.listSendChecksTransactions();
              }
            },
            errResponse => {
              if (errResponse.error) {
                this.utility.getMessageError(errResponse.error);
                this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
              }
            }
          );
      }
    });
  }

  payInvoiceTransaction(transactionOdj: any = null) {
    if (!this.userService.checkAvailableActions('payAcceptTransfer')) {
      return;
    }

    this.isLoading = true;

    const objDataDialog = {
      title: 'Please confirm your action',
      text: this.messages.get('PAY_INVOICE_TRANSACTION'),
      button_cancel_text: 'Cancel',
      button_confirm_text: 'Confirm & Pay',
      checkbox_confirm: false,
    };
    this.dialogService.openDialog(objDataDialog).afterClosed().subscribe(result => {
      if (result) {
        this.http.post<any>(this.host + '/client/check/invoice/accept', {
          ci_token: transactionOdj.ci_token
        })
          .subscribe(
            response => {
              if (response.success) {
                this.isLoading = false;
                this.topAlertsService.popToast('success', 'Success', this.messages.get('PAY_INVOICE_SUCESSFULLY'));
                this.listSendChecksTransactions();
              }
            },
            errResponse => {
              this.isLoading = false;
              if (errResponse.error) {
                this.utility.getMessageError(errResponse.error);
                this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
              }
            }
          );
      } else {
        this.isLoading = false;
      }
    });
  }

  getBunkInfoForReciever(bank_obj: any) {
    if (!bank_obj.rec_fs_token) {
      return;
    }

    const url = '/dwl/customer/funding-source/account';
    this.http.get<any>(this.host + url, {params: {fundingsource: bank_obj.rec_fs_token}})
      .subscribe(
        response => {
          if (response.success) {
            this.checkPopupInfo.reciever_info = response;
          }
        },
        errResponse => {
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
          }
        }
      );
  }

  getBunkInfoForSender(bank_obj: any) {
    if (!bank_obj.sndr_fs_token) {
      return;
    }

    const url = '/dwl/customer/funding-source/account';
    this.http.get<any>(this.host + url, {params: {fundingsource: bank_obj.sndr_fs_token}})
      .subscribe(
        response => {
          if (response.success) {
            this.checkPopupInfo.sender_info = response;
          }
        },
        errResponse => {
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
          }
        }
      );
  }
  getBunkInfoForDialog(bank_obj: any) {
    let url = '/dwl/customer/funding-source/account';
    if (this.userService.isCustomer()) {
      url = '/dwl/client/funding-source/account';
    }
    this.http.get<any>(this.host + url, {params: {fundingsource: bank_obj.dwl_fs_token}})
      .subscribe(
        response => {
          if (response.success) {
            bank_obj.account_bank = response.account;
            bank_obj.bank_info = response.bank_info;
            this.checkPopupInfo = bank_obj;
          }
        },
        errResponse => {
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
          }
        }
      );
  }

  callAction(stringAction: string = '') {
    eval(stringAction);
  }

  getTransferType(  objTrans: any ) {
    return this.transService.getTransferType( objTrans );
  }

}


