<div class="element-wrapper">
  <div class="row">
    <div class="table-responsive">
      <div class="pricing-plans row no-gutters mx-auto">
        <div class="pricing-plan with-hover-effect"  *ngFor="let viewPlan of listViewOurPlan"
             [ngClass]="userService.isSubDomenSandBox() ? 'sandbox-margin' : {'selected_start_planDisabled' : isBlockSelectedPlan(viewPlan.p_token),
             'col-md-6 col-sm-6 col-lg-3': userService.isIndividualAccount(),
             'col-md-6 col-sm-6 col-lg-6': !userService.isIndividualAccount() }">
          <div class="plan-head">
            <div class="plan-name">
              {{viewPlan.name}}
            </div>
          </div>
          <div class="plan-body" style="min-height: 180px;">
            <div class="plan-price-w">
              <div class="price-value" style="font-size: 1.5rem;">
                <span *ngIf="viewPlan.amount != '0.00'">
                  <span *ngIf="subPTocken == viewPlan.p_token">
                    <i class="icon-feather-check" style="font-size: 20px;"></i>
                  </span>
                  {{viewPlan.amount | currency:'':'symbol'}}
                  <div class="price-label" style="text-transform: lowercase;border-top: none;">per month</div>
                </span>
                <span *ngIf="viewPlan.amount == '0.00'">
                  Free forever
                  <div class="price-label" style="text-transform: lowercase;border-top: none;">&nbsp;</div>
                </span>
              </div>
              <p style="min-height: 44px;margin-bottom: 0px; margin-top: 20px; padding-bottom: 20px;">{{viewPlan.note}}</p>
            </div>
          </div>
          <div class="plan-description">
            <ul>
              <li>
                <b>Send Payments for <span class="text-primary ">{{viewPlan.send.over | currency:'':'symbol'}}</span> / Transaction</b>
              </li>
              <li>
                <b>Accept Payments For <span class="text-primary">{{viewPlan.receive.verify}}% + {{viewPlan.receive.limit.transaction_fee | currency:'':'symbol'}}</span></b>
              </li>
              <li><b>Real Time Payment Tracking</b></li>
              <li *ngIf="viewPlan.next_day == 0"><b>Standard Transfers Times</b></li>
              <li *ngIf="viewPlan.next_day == 1"><b>Next Day Transfers</b></li>
              <li *ngIf="viewPlan.direct_deposit == '1'"><b>Direct Deposit</b></li>
              <li *ngIf="viewPlan.api_access == '1'"><b>Api Access</b></li>
            </ul>
          </div>
          <div class="plan-btn-w">
            <button (click)="addMerchandPlan(viewPlan.p_token)" class="btn btn-primary btn-block"
                [disabled]="isDisableSelectPlan(viewPlan.p_token)"
                style="max-width: 95%;margin: auto;padding-top: 10px; padding-bottom: 10px;">
              {{getButtonTitleBuyPlan( viewPlan )}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div (click)="getListViewOurPlans()" id="initListViewOurPlans" style="display: none;">&nbsp;</div>
