import { Injectable } from '@angular/core';
import * as faker from 'faker';


@Injectable({
  providedIn: 'root'
})
export class FakersService {

  constructor() { }

  getCompanyName() {
    return faker.company.companyName();
  }

  getEmail() {
    return faker.internet.email( null, null, 'test.dev' );
  }

  getFullName() {
    return faker.name.firstName() + ' ' + faker.name.lastName();
  }

  getPassword() {
    return faker.internet.password(10);
  }

  getFirstName() {
    return faker.name.firstName();
  }

  getLastName() {
    return faker.name.lastName();
  }
}
