<ng-http-loader
  [backgroundColor]="'#047bf8'"
  [spinner]="spinkit.skWave"
  [debounceDelay]="100"
  *ngIf="isLoading"
  [minDuration]="300">
</ng-http-loader>

<div class="element-wrapper" style="height: 100vh !important">
  <div class="element-box col-lg-12 col-md-12" style="margin: auto; padding: 0; height: auto !important">
    <div style="height: auto !important; justify-content: center;">
      <div class="row d-md-block d-lg-block d-sm-block d-block d-xl-flex new_flex mx-0 py-6" style="height: 100vh !important">
        <div class="col-lg-6 col-md-12 col-sm-12 py-3" style="background-color: white;">
          <div class="mx-auto justify-content-center py-5" style="width: 85%; height: 90%;">
            <div class="row mx-0 text-center" style="align-items:center;">
              <div class="row mx-0">
                <img alt="" style="max-width: 75px;" src="assets/img/colored.png"> <h3 class="text-bold px-3 mb-0" style="color: #5073EF;align-self: center;font-weight: 700;">PAYNOTE</h3>
              </div>
            </div>
            <div class="row mx-0 mt-2"></div>
          </div>
          <div class="d-flex mx-auto mt-2 justify-content-between" style="width: 85%; font-size: 16px;">
            <div class="col-7 p-0">
              <a href="http://seamlesschex.com/paynote">Powered by PAYNOTE</a>
            </div>
            <div class="col-5 text-right p-0">
              <a class="mx-3" href="https://www.seamlesschex.com/terms-of-service">Terms</a>
              <a class="mx-3" href="https://www.seamlesschex.com/privacy-policy">Privacy</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12" style="background-color: #5073EF; color: white;">
          <div class="mx-auto justify-content-center middle-body py-5 payment-complete" style="width: 85%; height: 100%; padding-top: 40% !important; padding-bottom: 40% !important;" *ngIf="isSuccess">
            <div class="text-center" style="align-items:center; padding-bottom: 10px;">
              <img class="filter-white" src="/assets/img/check-accept-approve.svg" style="max-width: 75px;">
            </div>
            <div class="text-center">
              <h1 style="color: white;line-height: 1.4; font-weight: 300;">
                Success!
              </h1>
            </div>
            <div class="text-center">
              <h3 style="color: white; line-height: 1.4; font-weight: 300;">Bank Account has been authorized.</h3>
            </div>
          </div>
          <div class="mx-auto justify-content-center middle-body py-5 payment-complete" style="width: 85%; height: 100%; padding-top: 40% !important; padding-bottom: 40% !important;" *ngIf="isError">
            <div class="text-center">
              <h1 style="color: white;line-height: 1.4; font-weight: 300;">
                Something went wrong.
              </h1>
            </div>
            <div class="text-center">
              <h3 style="color: white; line-height: 1.4; font-weight: 300;">Please ensure you are using the correct link.</h3>
            </div>
          </div>

          <div class="d-flex new_padding mx-auto justify-content-center middle-body" *ngIf="!isSuccess && !isError" style="width: 65%; padding-bottom: 10px; height: 90%;" [ngStyle]="{'padding-top' : '10%'}">
            <form name="form" (ngSubmit)="f.form.valid && openPlaidModal()" [formGroup]="signUpForm" #f="ngForm" class="form-validation">
              <p class="pay-link-text-body"><b>Step 1:</b> Enter the fields below:</p>
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group" [ngClass]="{'has-error has-danger': signUpForm.get('name').errors && (signUpForm.get('name').dirty || signUpForm.get('name').touched)}">
                    <label for="name">Account Holder Name</label>
                    <input autocomplete="off" id="name" class="form-control" placeholder="Account Holder Name" type="text" name="name"
                           required [formControlName]="'name'">
                    <div *ngIf="(signUpForm.get('name').errors && (signUpForm.get('name').dirty || signUpForm.get('name').touched)) &&signUpForm.get('name').errors.required"
                         class="help-block form-text with-errors form-control-feedback">Name is required
                    </div>
                    <div *ngIf="(signUpForm.get('name').errors && (signUpForm.get('name').dirty || signUpForm.get('name').touched)) && signUpForm.get('name').errors.validFullName"
                         class="help-block form-text with-errors form-control-feedback">Invalid Name
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group" [ngClass]="{'has-error has-danger': signUpForm.get('email').errors && (signUpForm.get('email').dirty || signUpForm.get('email').touched)}">
                    <label for="email">Email</label>
                    <input autocomplete="off" id="email" name="email" [formControlName]="'email'" class="form-control" placeholder="Email" type="email" required>
                    <div *ngIf="(signUpForm.get('email').errors && (signUpForm.get('email').dirty || signUpForm.get('email').touched)) &&signUpForm.get('email').errors.required"
                         class="help-block form-text with-errors form-control-feedback">Email is required
                    </div>
                    <div *ngIf="(signUpForm.get('email').errors && (signUpForm.get('email').dirty || signUpForm.get('email').touched)) &&signUpForm.get('email').errors"
                         class="help-block form-text with-errors form-control-feedback">Invalid Email
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group" [ngClass]="{'has-error has-danger': signUpForm.get('phone').errors && (signUpForm.get('phone').dirty || signUpForm.get('phone').touched)}">
                    <label for="signUpPhoneInput">Mobile Phone</label>
                    <input id="signUpPhoneInput" name="phone" [formControlName]="'phone'" class="form-control" placeholder="Mobile Phone" type="text" required [textMask]="{ mask: utility.maskPhone }"/>
                    <div *ngIf="signUpForm.get('phone').errors && (signUpForm.get('phone').dirty || signUpForm.get('phone').touched)"
                         class="help-block form-text with-errors form-control-feedback">
                      <span *ngIf="signUpForm.get('phone').errors.validPhone">Mobile Phone must be at least 10 digits long.</span>
                      <span *ngIf="signUpForm.get('phone').errors.required">Mobile Phone is required</span>
                      <span *ngIf="signUpForm.get('phone').errors.unique">This phone number is already in use</span>
                    </div>
                  </div>
                </div>

              </div>
              <p class="pay-link-text-body mt-3 "><b>Step 2:</b> Click the  "Authorize" button to securely link your bank account using your online banking credentials. This process is both free and ensures the encryption and verification of your bank information through Plaid.</p>
              <div class="buttons-w form-group button_submit_request_invoice text-center" style="margin-top: 0px;">
                <button id="sendCheckButton" style="background-color: white; color: black; font-weight: 500 !important; height: 40px;width: min-content" type="submit" [disabled]="!signUpForm.valid" class="btn btn-lg">
                  <span style="opacity: 1 !important;">Authorize</span>
                </button>
              </div>
            </form>
            <br>
            <br>
          </div>
          <div *ngIf="merchant.is_on_demand && !isSuccess && !isError" class="text-center w-75 mx-auto pb-3 bottom-text">
            I agree that future payments to {{merchant.name}} will be processed by Paynote Payment System. In Order to cancel this authorization, I will reach out to {{merchant.name}}.
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #showSuccess let-c="close" let-d="dismiss">
  <div class="modal-blue-bg onboarding-content">
    <div class="modal-header" style="padding-bottom: 0">
      <div>
      </div>
      <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
        <span aria-hidden="true" style="color: #949494; font-size: 20px; font-weight: bold">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row justify-content-center mb-3">
        <img src="assets/img/checkmark-circle.svg" width="48" height="48">
      </div>
      <div class="row justify-content-center mb-2" style="font-size: 18px;">
        <b>Success!</b>
      </div>
      <div class="row justify-content-center mb-4" style="font-size: 14px;">
        Bank Account has been authorized.
      </div>
    </div>
  </div>
</ng-template>
