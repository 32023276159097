import { AbstractControl } from '@angular/forms';

export function validateBirthDate(control: AbstractControl) {

  if (control.value == '' || control.value == null) {
    return null;
  }

  const intTimeUser = control.value.getTime();
  const objDateNow = new Date();
  const intYearNow = objDateNow.getFullYear();
  objDateNow.setFullYear(intYearNow - 18);
  const intTimeNow = objDateNow.getTime();

  if (intTimeUser > intTimeNow) {
    return { validBirthDate: true };
  }
  return null;
}
