<ng-http-loader
    [backgroundColor]="'#047bf8'"
    [spinner]="spinkit.skWave"
    [debounceDelay]="100"
    *ngIf="!objExpressCheckoutComp.objExprCheckRequest.saveBankDetails"
    [minDuration]="300">
</ng-http-loader>

<!--<noscript>-->
<!--  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W7RK4F" height="0" width="0" style="display:none;visibility:hidden"></iframe>-->
<!--</noscript>-->

<button aria-label="Close" class="close" data-dismiss="modal" type="button" *ngIf="ifIframeMode()" (click)="closeIframe()">
  <span class="os-icon os-icon-close"></span>
</button>
<div class="" *ngIf="!objExpressCheckoutComp.objExprCheckRequest.saveBankDetails"
     [ngClass]="{ 'express-checkout-body': !ifIframeMode() }">
  <div class="auth-box-w">
    <div class="logo-w" style="padding: 5%;">
      <img alt="Logo" src="{{getLogo()}}" style="max-height: 120px;">
    </div>
    <div style="padding: 15px 15px 25px 15px;">
      <h4 class="auth-header text-center" style="padding: 15px 15px 25px 15px;">
        {{objExpressCheckoutComp.objExprCheckRequest.lightBox.title || 'Pay with Paynote'}}
      </h4>
      <label *ngIf="objExpressCheckoutComp.objExprCheckRequest.lightBox.subtitle" style="padding: 0.75rem;">
        {{objExpressCheckoutComp.objExprCheckRequest.lightBox.subtitle}}
      </label>
      <label style="padding: 0.75rem; font-weight: 500; width: 100%;">Cart Summary: </label>
      <br>
      <label class="text-center" style="padding: 0.75rem;" *ngIf="objExpressCheckoutComp.objExprCheckRequest.user">
        <strong>Hi, {{objExpressCheckoutComp.objExprCheckRequest.user.name || ''}}!</strong>
      </label>
      <hr>
      <div class="table-responsive" *ngIf="objExpressCheckoutComp.objExprCheckRequest.checkout.items.length && !objExpressCheckoutComp.objExprCheckRequest.saveBankDetails">
        <table class="table table-lightborder">
          <tbody>
            <tr *ngFor="let item of objExpressCheckoutComp.objExprCheckRequest.checkout.items">
              <td>{{item.title}}</td>
              <td class="text-right">
                {{item.price | currency:'':'symbol'}}&nbsp;
              </td>
            </tr>
            <tr>
              <td class="fw-bold">Total <small>+ Shipping & Discount</small> </td>
              <td class="text-right">
                {{objExpressCheckoutComp.objExprCheckRequest.checkout.totalValue | currency:'':'symbol'}}&nbsp;
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr>
      <div  class="table-responsive" *ngIf="objExpressCheckoutComp.objExprCheckRequest.checkout.description || true" style="padding: 15px 15px 25px 15px;">
        <label>{{objExpressCheckoutComp.objExprCheckRequest.checkout.description || ''}}</label>
      </div>
      <div class="text-center" *ngIf="objExpressCheckoutComp.objExprCheckRequest.saveBankDetails" style="margin-bottom: 5px">
        <span>
          I agree that future payments to {{objExpressCheckoutComp.objExprCheckRequest.storeName}} will be processed by Dwolla Payment System from the selected account above. In order to cancel this authorization, I will reach out to {{objExpressCheckoutComp.objExprCheckRequest.storeName}}.
        </span>
        <br>
      </div>
      <div class="text-center">
        <button class="btn btn-brand btn-block" (click)="openPlaidModal()" style="width: 90%; display: inline-block;"
                *ngIf="!objExpressCheckoutComp.canShowExpressCheckout && this.objExpressCheckoutComp.objExprCheckRequest.lightBox.show_cart_summary">
          Checkout Now <img src="./assets/img/Vector-2.svg" width="10px" height="15px">
        </button>
        <button class="btn btn-brand btn-block" (click)="submitPayment()" style="width: 90%; display: inline-block;"
                *ngIf="objExpressCheckoutComp.canShowExpressCheckout && !objExpressCheckoutComp.objExprCheckRequest.saveBankDetails">
          {{objExpressCheckoutComp.objExprCheckRequest.lightBox.formButtonLabel || 'PAY'}}
        </button>
      </div>
      <hr *ngIf="!ifIframeMode()">
      <a style="font-size: 12px;" href="{{getReturnUrl()}}" *ngIf="!ifIframeMode()">
        Cancel and return to {{getStoreName()}}
      </a>
    </div>
  </div>
</div>
