<div class="element-wrapper">
    <h6 class="element-header">
        <span>Accept Checks</span>
    </h6>
    <div class="element-box element-box-custom" *ngIf="userService.isMerchant() || userService.isCustomer()">
        <div class="row wrap-filters" style="border-bottom: 1px solid gainsboro;">
        </div>
        <div class="table-responsive">
            <div class="dataTables_wrapper container-fluid dt-bootstrap4">
                <div class="row">
                    <div class="col-sm-12">
                        <br>
                        <table id="dataTable12" width="100%" class="table table-striped table-lightfont dataTable sendChecks responsive-table" role="grid" aria-describedby="dataTable1_info" style="width: 100%;">
                            <thead>
                                <tr role="row">
                                    <th scope="col">Amount</th>
                                    <th scope="col" *ngIf="!userService.isClient()">Recipient</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr role="row" *ngFor="let transactionMerchant of lstSendChecks">
                                    <td data-title="Amount">{{getAmountString(transactionMerchant || '&nbsp;')}}</td>
                                    <td data-title="Recipient" *ngIf="!userService.isClient()">
                                        {{transactionMerchant.rec_name || '&nbsp;'}}<br>{{transactionMerchant.rec_email || '&nbsp;'}}
                                    </td>
                                    <td data-title="Description">{{transactionMerchant.description || '&nbsp;'}}</td>
                                    <td data-title="Date">{{userService.getDateFormat(transactionMerchant.created_at)}}</td>
                                    <td data-title="Action">
                                        <button placement="top" ngbTooltip="Resend Notification" *ngIf="canResendNotification(transactionMerchant, 'check')" style="margin: 3px;padding: 5px 13px;" class="btn btn-primary btn-sm btn-rounded" type="button" (click)="resendNotificationCheck(transactionMerchant)">
                    <i class="fa fa-bell-o"></i>&nbsp;<span class='accdec'>Resend</span>
                  </button>

                                        <button placement="top" ngbTooltip="Resend Notification" *ngIf="canResendNotification(transactionMerchant, 'invoice') && permissionsService.isOnlyRead()" style="margin: 3px;padding: 5px 13px;" class="btn btn-primary btn-sm btn-rounded" type="button" (click)="resendNotificationInvoice(transactionMerchant)">
                    <i class="fa fa-bell-o"></i>&nbsp;<span class='accdec'>Resend</span>
                  </button>
                                        <button placement="top" ngbTooltip="Pay" style="margin: 3px;padding: 5px 13px;" class="btn btn-primary btn-sm btn-rounded" type="button" (click)="payInvoiceTransaction(transactionMerchant)" *ngIf="canInvoicePay(transactionMerchant) && permissionsService.isOnlyRead()"
                                            [disabled]="isLoading">
                    <i class="picons-thin-icon-thin-0424_money_payment_dollar_cash"></i>&nbsp;<span class='accdec'>Pay</span>
                  </button>
                                        <button placement="top" ngbTooltip="Accept" style="margin: 3px;padding: 5px 13px;" class="btn btn-primary btn-sm btn-rounded" type="button" (click)="acceptTransactionCheck(transactionMerchant)" *ngIf="canAcceptFunds(transactionMerchant) && permissionsService.isOnlyRead()">
                    <i class="fa fa-check"></i>&nbsp;<span class='accdec'>Accept</span>
                  </button>
                                        <a placement="top" ngbTooltip="Accept" style="margin: 3px;padding: 5px 13px;" class="btn btn-primary btn-sm btn-rounded" href="{{getAcceptFundsByLink(transactionMerchant)}}" *ngIf="canAcceptFundsByLink(transactionMerchant) && permissionsService.isOnlyRead()">
                                            <i class="fa fa-check"></i>&nbsp;<span class='accdec'>Accept</span>
                                        </a>
                                        <button placement="top" ngbTooltip="View Check Info" *ngIf="canViewCheckInfo(transactionMerchant)" style="margin: 3px;padding: 5px 13px;" class="btn btn-primary btn-sm btn-rounded" type="button" (click)="sendCheckInfoPopup( sendChacksDialog, transactionMerchant )">
                    <i class="fa fa-search"></i>&nbsp;<span class='accdec'>View</span>
                  </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *ngIf="!lstSendChecks.length" class="ssg-nothing-found">
                    <div class="icon-w"><i class="os-icon os-icon-eye-off"></i></div>
                    <span>No accept checks were found. Try changing your query...</span>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12">
                        <div *ngIf="paginationService.visiblePagination && !isLightVersionAC" class="dataTables_info" id="dataTable1_info" role="status" aria-live="polite">
                            <div class="showentries">Show entries</div>
                            <ng-pagination [position]="'left'" [pageSize]="paginationService.pageSize" [itemsCount]="paginationService.itemsCount" (getPageData)="listSendChecksTransactions($event)">
                            </ng-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #sendChacksDialog let-c="close" let-d="dismiss">
    <div class="modal-blue-bg onboarding-content" style="padding: 0;">
        <div class="modal-header">
            <h4 class="modal-title">Check Info</h4>
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>

        <div class="checkWrapper">
            <div class="check">
                <div class="checkElement previewCurrentDate">{{userService.getDateFormat(checkPopupInfo.created_at)}}</div>
                <div class="checkElement previewReceiver" [innerHTML]="checkPopupInfo.rec_name"></div>
                <div class="checkElement previewAmount">{{checkPopupInfo.amount | currency: '' :''}}</div>
                <div class="checkElement previewAmountWord">{{utility.toWords(checkPopupInfo.amount | currency: '' :'')}}</div>
                <div class="checkElement previewDesc">{{checkPopupInfo.description}}</div>
                <div class="checkElement previewBankName">
                    <span *ngIf="(userService.isMerchant() || userService.isClient()) && checkPopupInfo.bank_info" [innerHTML]="checkPopupInfo.bank_info.name || ''"></span>
                    <span *ngIf="(userService.isAdmin() || userService.isSuperAdmin()) && checkPopupInfo.sender_info && checkPopupInfo.sender_info.bank_info"
                          [innerHTML]="checkPopupInfo.sender_info.bank_info.name"></span>
                </div>
                <div class="checkElement previewSignWrapper">
                    <div class="previewWithOutSignWrapper">
                        <span *ngIf="checkPopupInfo.sender && checkPopupInfo.sender.u_token == userService.getToken()">
          <span *ngIf="!userService.getSignature()" class="signatureName sig1">{{userService.getFullName()}}</span>
                        <img *ngIf="userService.getSignature()" class="signature" src="{{userService.getSignature()}}">
                        </span>
                        <span *ngIf="checkPopupInfo.sender && checkPopupInfo.sender.u_token != userService.getToken()">
          <span *ngIf="!checkPopupInfo.signature" class="signatureName sig1">{{checkPopupInfo.sndr_name}}</span>
                        <img *ngIf="checkPopupInfo.signature" class="signature" src="{{checkPopupInfo.signature}}">
                        </span>
                        <span *ngIf="!checkPopupInfo.sender">
          <span class="signatureName">{{checkPopupInfo.sndr_name}}</span>
                        </span>
                    </div>
                </div>
                <div class="checkElement infoWrapper">
                    <div>
                        <div class="previewSenderName" [innerHTML]="checkPopupInfo.sndr_name"></div>
                        <div class="smallText">{{checkPopupInfo.sndr_email}}</div>
                    </div>
                </div>
                <div class="checkElement routingNumber">A{{getRoutingNumber()}}A</div>
                <div class="checkElement accountNumber">******{{getAccountNumber()}}</div>
                <div class="checkElement userCheckNumber">{{checkPopupInfo.number}}</div>
            </div>
        </div>
        <div class="modal-body">
            &nbsp;&nbsp;
            <!--</div>-->
            <div class="profile-tile">
                <span class="profile-tile-box">
      <div class="pt-avatar-w" style="border-radius: 0px;">
        <i style="color:#aab7c8; font-size: 5em;" class="picons-thin-icon-thin-0414_money_cash_coins_payment"></i>
      </div>
    </span>

                <div class="profile-tile-meta" style='font-size: 22px;'>
                    <ul *ngIf="userService.isAdmin() || userService.isSuperAdmin()">
                        <li style="margin-bottom: 15px">
                            <div>
                                <span class="added_withdrawn">Date:</span>
                                <span class="added_withdrawn_source">&nbsp;{{userService.getDateFormat(checkPopupInfo.created_at,  'MMM D, YYYY hh:mm:ss A')}}</span>
                            </div>
                            <div>
                                <span class="added_withdrawn">Date Paid:</span>
                                <span class="added_withdrawn_source">&nbsp;
              <span *ngIf="checkPopupInfo.payment_at">
                {{userService.getDateFormat(checkPopupInfo.payment_at, 'MMM D, YYYY hh:mm:ss A')}}
              </span>
                                </span>
                            </div>
                            <div>
                                <span class="added_withdrawn">Description:</span>
                                <span class="added_withdrawn_source">&nbsp;{{checkPopupInfo.description}}</span>
                            </div>
                            <div>
                                <span class="added_withdrawn">Type:</span>
                                <span class="added_withdrawn_source">&nbsp;{{checkPopupInfo.type}}</span>
                            </div>
                            <div>
                                <span class="added_withdrawn">Recurring:</span>
                                <span class="added_withdrawn_source">&nbsp;{{isReccuring( checkPopupInfo.type ) ? 'Yes' : 'No'}}</span>
                            </div>
                            <div>
                                <span class="added_withdrawn">Transfer:</span>
                                <span class="added_withdrawn_source">&nbsp;{{ getTransferType(checkPopupInfo) }}</span>
                            </div>
                            <div *ngIf="canViewFeesOnCheck(checkPopupInfo)">
                                <span class="added_withdrawn">Fee:</span>
                                <span class="added_withdrawn_source">&nbsp;{{checkPopupInfo.amount_fee | currency:'':'symbol'}}</span>
                            </div>
                        </li>

                        <li>
                            <div>
                                <span class="added_withdrawn"><b>TO:</b></span>
                                <span class="added_withdrawn_source">&nbsp;</span></div>
                        </li>
                        <li style="margin-bottom: 15px">
                            <div>
                                <span class="added_withdrawn">Name:</span>
                                <span class="added_withdrawn_source" [innerHTML]="' '+checkPopupInfo.rec_name"></span>
                            </div>
                            <div>
                                <span class="added_withdrawn">Email: </span>
                                <a href="/users/{{checkPopupInfo.reciever_info.account.dwl_token}}/{{checkPopupInfo.reciever_info.account.u_token}}" *ngIf="checkPopupInfo.reciever_info && checkPopupInfo.reciever_info.account">{{checkPopupInfo.rec_email}}</a>
                                <span *ngIf="!checkPopupInfo.reciever_info || !checkPopupInfo.reciever_info.account" class="added_withdrawn_source" [innerHTML]="' '+checkPopupInfo.rec_email"></span>
                            </div>
                            <div *ngIf="checkPopupInfo.reciever_info && checkPopupInfo.reciever_info.bank_info">
                                <div>
                                    <span class="added_withdrawn">Bank Name:</span>
                                    <span class="added_withdrawn_source">&nbsp;{{checkPopupInfo.reciever_info.bank_info.name}}</span>
                                </div>
                                <div>
                                    <span class="added_withdrawn">Last 4 digits of account:</span>
                                    <span class="added_withdrawn_source">&nbsp;**** {{checkPopupInfo.reciever_info.account.mask}}</span>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div>
                                <span class="added_withdrawn"><b>FROM:</b></span>
                                <span class="added_withdrawn_source">&nbsp;</span></div>
                        </li>
                        <li style="margin-bottom: 15px">
                            <div>
                                <span class="added_withdrawn">Name:</span>
                                <span class="added_withdrawn_source" [innerHTML]="' '+checkPopupInfo.sndr_name"></span>
                            </div>
                            <div>
                                <span class="added_withdrawn">Email: </span>
                                <a href="/users/{{checkPopupInfo.sender_info.account.dwl_token}}/{{checkPopupInfo.sender_info.account.u_token}}" *ngIf="checkPopupInfo.sender_info && checkPopupInfo.sender_info.account">{{checkPopupInfo.sndr_email}}</a>
                                <span *ngIf="!checkPopupInfo.sender_info || !checkPopupInfo.sender_info.account" class="added_withdrawn_source">&nbsp;{{checkPopupInfo.sndr_email}}</span>
                            </div>
                            <div *ngIf="checkPopupInfo.sender_info && checkPopupInfo.sender_info.bank_info">
                                <div>
                                    <span class="added_withdrawn">Bank Name:</span>
                                    <span class="added_withdrawn_source">&nbsp;{{checkPopupInfo.sender_info.bank_info.name}}</span>
                                </div>
                                <div>
                                    <span class="added_withdrawn">Last 4 digits of account:</span>
                                    <span class="added_withdrawn_source">&nbsp;**** {{checkPopupInfo.sender_info.account.mask}}</span>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div>
                                <span class="added_withdrawn">STATUS:</span>
                                <!--<span class="added_withdrawn_source" [innerHTML]="getStatus(checkPopupInfo)"></span>-->
                                <span class="added_withdrawn_source" *ngIf="checkPopupInfo.status != 'processed'" [innerHTML]="transService.getStatus(checkPopupInfo)"></span>
                                <span class="added_withdrawn_source" *ngIf="checkPopupInfo.status == 'processed'">Paid <i class="fa fa-question-circle" placement="top" ngbTooltip="{{transService.getStatus(checkPopupInfo)}}"></i></span>
                            </div>
                            <div>
                                <span class="added_withdrawn">TRANSACTION ID:</span>
                                <span class="added_withdrawn_source">&nbsp;{{checkPopupInfo.dwl_t_token}}</span>
                            </div>
                        </li>
                    </ul>
                    <ul *ngIf="userService.isMerchant() || userService.isClient()">
                        <li>
                            <div>
                                <span class="added_withdrawn">Name:</span>
                                <span class="added_withdrawn_source" [innerHTML]="' '+checkPopupInfo.rec_name"></span>
                            </div>
                            <div>
                                <span class="added_withdrawn">Email</span>
                                <span class="added_withdrawn_source">&nbsp;
                  {{checkPopupInfo.rec_email}}
            </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span class="added_withdrawn">Date:</span>
                                <span class="added_withdrawn_source">&nbsp;
                  {{userService.getDateFormat(checkPopupInfo.created_at)}}
            </span>
                            </div>
                            <div>
                                <span class="added_withdrawn">Date Paid:</span>
                                <span class="added_withdrawn_source">&nbsp;
              <span *ngIf="checkPopupInfo.payment_at">
                {{userService.getDateFormat(checkPopupInfo.payment_at)}}
              </span>
                                </span>
                            </div>
                        </li>
                        <li></li>
                        <li>Number:&nbsp;<span class="added_withdrawn_source">{{checkPopupInfo.number}}</span></li>
                        <li>Amount:&nbsp;<span class="added_withdrawn_source">{{getAmountString(checkPopupInfo)}}</span></li>
                        <li *ngIf="canViewFeesOnCheck(checkPopupInfo)">
                            Fee:&nbsp;<span class="added_withdrawn_source">{{checkPopupInfo.amount_fee | currency:'':'symbol'}}</span>
                        </li>
                        <li>Status:&nbsp;
                            <!--<span class="added_withdrawn_source" [innerHTML]="getStatus(checkPopupInfo)"></span>-->
                            <span class="added_withdrawn_source" *ngIf="checkPopupInfo.status != 'processed'" [innerHTML]="transService.getStatus(checkPopupInfo)"></span>
                            <span class="added_withdrawn_source" *ngIf="checkPopupInfo.status == 'processed'">Paid <i class="fa fa-question-circle" placement="top" ngbTooltip="{{transService.getStatus(checkPopupInfo)}}"></i></span>
                        </li>
                        <li>Description:&nbsp;<span class="added_withdrawn_source">{{checkPopupInfo.description}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <!--<button type="button" class="btn btn-secondary" (click)="c('Close click')">Close</button>-->
            <button class="slick-prev slick-arrow" type="button" (click)="c('Close click')">Cancel</button>
        </div>
    </div>
</ng-template>

<ng-template #failedInfoDialog let-c="close" let-d="dismiss">
    <div class="manual-verification modal-blue-bg onboarding-content">
        <div class="modal-header">
            <h4 class="modal-title center">Failed Transaction</h4>
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
        </div>
        <div class="modal-body">
            <div class="profile-tile">
                <div class="profile-tile-meta" style='font-size: 22px;'>
                    <ul>
                        <li>Code:&nbsp;<span class="added_withdrawn_source">{{failedPopupInfo.code || '&nbsp;'}}</span></li>
                        <li>Date:&nbsp;<span class="added_withdrawn_source">{{userService.getDateFormat(failedPopupInfo.created || '&nbsp;')}}</span></li>
                        <li>Description:&nbsp;<span class="added_withdrawn_source">{{failedPopupInfo.description || '&nbsp;'}}</span></li>
                        <li>Explanation:&nbsp;<span class="added_withdrawn_source">{{failedPopupInfo.explanation || '&nbsp;'}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="slick-prev slick-arrow" type="button" (click)="c('Close click')">Cancel</button>
        </div>
    </div>
</ng-template>
