export const environment = {
  subDomen: "paynote",
  production: true,
  canSwitchToSandBox: true,
  isSandBoxMode: false,
  isLocal: false,
  hostOriginal: "https://paynote.seamlesschex.com/#",
  //hostWithOutSandbox: 'https://paynote.seamlesschex.com/api',
  hostWithOutSandbox: "https://paynote-wapi.seamlesschex.com/api",
  host:
    localStorage.getItem("sandBoxMode") == "0" ||
    !localStorage.getItem("sandBoxMode")
      ? "https://paynote-wapi.seamlesschex.com/api"
      : "https://paynote-wapi.seamlesschex.com/sandbox",
  //host: localStorage.getItem('sandBoxMode') == '0' || !localStorage.getItem('sandBoxMode') ? 'https://paynote.seamlesschex.com/api' : 'https://paynote.seamlesschex.com/sandbox',
  //  host: 'https://paynote.seamlesschex.com/api',
  //sandboxHost: 'https://paynote-wapi.seamlesschex.com/sandbox',
  sandboxHost: "https://paynote.seamlesschex.com/sandbox",
  sandboxHostUpdated: "https://paynote-sandbox.seamlesschex.com/#",
  liveHost: "https://paynote.seamlesschex.com",
  dubugModeForRegDwolla: false,
  debugMode: false,
  link: {
    privacy_policy: "https://www.seamlesschex.com/privacy-policy/",
    terms_of_service: "https://www.seamlesschex.com/terms-of-service/",
    api_documentation: "https://developers.seamlesschex.com/paynote/docs/",
  },
  api: {
    live_endpoint: "https://api-paynote.seamlesschex.com/v1",
    sandbox_endpoint: "https://sandbox-paynote.seamlesschex.com/v1",
  },
  availableAPIMode: true,
  availableEmailSettings: true,
  availablePhoneVerification: false,
  availableMarketPlaceSettings: false,
  availableSignUpByEmal: true,
  availablePartner: true,
  availableUser: true,
  HUBSPOT_ID: 20811928,
  plaid: {
    selectAccount: true,
    env: "production",
    clientName: "SeamlessChex",
    apiVersion: "v2",
    key: "1902b1de5ce38dfe7586c08873d297",
    product: ["auth"],
    countryCodes: ["US"],
  },
  plaidSandbox: {
    selectAccount: true,
    env: "sandbox",
    clientName: "SeamlessChex",
    apiVersion: "v2",
    key: "1902b1de5ce38dfe7586c08873d297",
    product: ["auth"],
  },
  mapbox: {
    access_key:
      "pk.eyJ1Ijoic2VhbWxlc3NjaGV4IiwiYSI6ImNrNDFmN2pkbDAwcjczbnM2ZHBub3Vzc28ifQ.bpHM4yvXrHSBNxLUQ_lE_w",
  },
  react_remote_module: "/react-entry/remoteEntry.js",
  python_api: "https://paynote-api-py.seamlesschex.com",
};
